import React, { useContext, useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { Box } from '@mui/material';
import {
  filtersObjWithDate,
  VisibleColumnsInterface,
} from '../../models/Filters';
import Filters from './Filters/Filters';
import Links from './Links/Links';
import FiltersClaims from './Filters/FiltersClaims';
import useOwners from '../../customHooks/useOwners';
import useProjects from '../../customHooks/useProjects';
import { UserContextType } from '../../models/User';
import Context from '../../context/UserContext';
import useLinkStatus from '../../customHooks/useLinkStatus';
import useSites from '../../customHooks/useSites';
import FiltersMaster from './Filters/FiltersMaster';
import FiltersAssigned from './Filters/FiltersAssigned';
import FiltersUnclaimed from './Filters/FiltersUnclaimed';
import useFavFilters from '../../customHooks/useFavFilters';
import DrawerLinks from './DrawerLinks';
import FormBox from './LinkForm/FormBox';
import { GlobalContextType } from '../../models/Context';
import GeneralContext from '../../context/GeneralActions';
import useFiltersLinks from '../../stores/filters/useFiltersLinks';
import { IS_USER_ADMIN } from '../../constants/user';
import useFastProjects from '../../customHooks/useFastProjects';

const DefaultView = () => {
  const [drawerForm, setDrawerForm] = useState(false);
  const { UserContext } = useContext(Context) as UserContextType;
  const { GlobalContext, setGlobalContext } = useContext(
    GeneralContext,
  ) as GlobalContextType;
  const { updateBaseFilter, baseFilter } = useFiltersLinks();
  const { owners } = useOwners({ withFields: true });
  const { projects } = useFastProjects({ id: UserContext.id });
  const { sites } = useSites({ userId: UserContext.id, minimal: true });
  const { statusOptions } = useLinkStatus();
  const { users: usersManagers } = useOwners({
    withFields: false,
    fullName: true,
    role: 'manager,administrator',
  });

  const [tab, setTab] = useState({ numberTab: 0, loadingTab: false });
  const { filters: favFiltersList } = useFavFilters();
  const [visibleColumns, setVisibleColumns] = useState<VisibleColumnsInterface>(
    {
      site__name: true,
      owner__username: true,
      affinity__name: true,
      template__title: true,
      audience__name: true,
      found_not_null: true,
      created: true,
      modified: true,
      status: true,
      notes_count: true,
      is_unsubscribed: true,
    },
  );

  const updateTab = (value: number, prop: boolean) =>
    setTab({ numberTab: value, loadingTab: prop });

  const updateFormDrawer = () => setDrawerForm(!drawerForm);

  useEffect(() => {
    if (
      GlobalContext.site_selected !== 0 &&
      projects.length > 0 &&
      (!baseFilter ||
        baseFilter.sites_ids.value !== `${GlobalContext.site_selected}`)
    ) {
      const siteId = GlobalContext.site_selected;
      const threeMonthsAgo = dayjs().subtract(3, 'month').format('YYYY-MM-DD');
      const todayDate = dayjs().format('YYYY-MM-DD');
      if (IS_USER_ADMIN.includes(UserContext.profile.role)) {
        const filterObject = {
          sites_ids: {
            id: 'sites_ids',
            value: '100001, 1000014,100000',
          },
          show_cannibalization_attempts: {
            id: 'show_cannibalization_attempts',
            value: '',
          },
          show_all: {
            id: 'show_all',
            value: 'true',
          },
          show_only_emails: { id: 'show_only_emails', value: '' },
          notify_opens: { id: 'notify_opens', value: '' },
          is_unsubscribed: { id: 'is_unsubscribed', value: '' },
          templates_ids: { id: 'templates_ids', value: '' },
          affinities_ids: { id: 'affinities_ids', value: '' },
          audiences_ids: { id: 'audiences_ids', value: '' },
          status: {
            id: 'status',
            value: '',
          },
          owner: { id: 'owner', value: '' },
          search_url: '',
          search_email: '',
          min_mod_date: { id: 'min_mod_date', value: threeMonthsAgo },
          max_mod_date: { id: 'max_mod_date', value: todayDate },
          min_added_date: { id: 'min_added_date', value: threeMonthsAgo },
          max_added_date: { id: 'max_added_date', value: todayDate },
          min_found_date: { id: 'min_found_date', value: null },
          max_found_date: { id: 'max_found_date', value: null },
        };
        updateBaseFilter(filterObject);
        return;
      }
      const projectWithSite = projects.find(project =>
        project.sites.some(site => site.id === siteId),
      );

      const filterObject = {
        sites_ids: {
          id: 'sites_ids',
          value: projectWithSite
            ? `${projectWithSite.sites.map(item => {
                return item.id;
              })}`
            : '',
        },
        show_cannibalization_attempts: {
          id: 'show_cannibalization_attempts',
          value: '',
        },
        show_all: {
          id: 'show_all',
          value: 'true',
        },
        show_only_emails: { id: 'show_only_emails', value: '' },
        notify_opens: { id: 'notify_opens', value: '' },
        is_unsubscribed: { id: 'is_unsubscribed', value: '' },
        templates_ids: { id: 'templates_ids', value: '' },
        affinities_ids: { id: 'affinities_ids', value: '' },
        audiences_ids: { id: 'audiences_ids', value: '' },
        status: {
          id: 'status',
          value: 'pending,to_do,contact_not_found,invalid_contact',
        },
        owner: { id: 'owner', value: `${UserContext.id}` },
        search_url: '',
        search_email: '',
        min_mod_date: { id: 'min_mod_date', value: threeMonthsAgo },
        max_mod_date: { id: 'max_mod_date', value: todayDate },
        min_added_date: { id: 'min_added_date', value: threeMonthsAgo },
        max_added_date: { id: 'max_added_date', value: todayDate },
        min_found_date: { id: 'min_found_date', value: null },
        max_found_date: { id: 'max_found_date', value: null },
      };
      updateBaseFilter(filterObject);
    }
  }, [GlobalContext.site_selected, projects]);

  return (
    <div className="grid_container_new">
      <DrawerLinks openDrawer={drawerForm} openCloseDrawer={updateFormDrawer}>
        {tab.numberTab === 0 && (
          <Filters
            columnsVisibility={visibleColumns}
            favFiltersList={favFiltersList}
            updateFormDrawer={updateFormDrawer}
            setColumnsVisible={setVisibleColumns}
            tab={tab}
            owners={owners}
            projects={projects}
            status={statusOptions.map(item => {
              return {
                id: item.value,
                name: item.name,
              };
            })}
          />
        )}
        {/* {tab.numberTab === 1 && (
          <FiltersAssigned
            owners={owners}
            sites={sites}
            updateFormDrawer={updateFormDrawer}
          />
        )} */}
        {tab.numberTab === 1 && (
          <FiltersUnclaimed
            columnsVisibility={visibleColumns}
            setColumnsVisible={setVisibleColumns}
            updateFormDrawer={updateFormDrawer}
            owners={owners}
            projects={projects}
          />
        )}
        {tab.numberTab === 2 && (
          <FiltersClaims
            sites={sites}
            owners={owners}
            updateFormDrawer={updateFormDrawer}
            managers={usersManagers}
          />
        )}
        {tab.numberTab === 3 && (
          <FiltersMaster
            sites={sites}
            owners={owners}
            updateFormDrawer={updateFormDrawer}
          />
        )}
      </DrawerLinks>
      <div className="right_columns">
        <Links
          updateFormDrawer={updateFormDrawer}
          columnsVisibility={visibleColumns}
          updateTab={updateTab}
          statusOfLinks={statusOptions}
        />
      </div>

      <Box className="right_columns" borderLeft="1px solid #D9D9D9">
        <FormBox tabView={tab.numberTab} />
      </Box>
    </div>
  );
};

export default DefaultView;
