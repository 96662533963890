const routesNames = {
  home: '/',
  links: '/links',
  templates: '/templates',
  prospecting: '/prospecting',
  login: '/login',
  settings: '/settings',
  users: '/settings/users',
  projectSites: '/settings/project-sites',
  rules: '/settings/rules',
  audienceaff: '/settings/audience-affinity',
  unsubscribe: '/unsubscribe',
  history: '/history',
};

export default routesNames;
