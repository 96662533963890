import { create } from 'zustand';
import {
  defaultLogs,
  filtersLogsDefault,
  FiltersLogsInterface,
  LogsRequestI,
} from '../../models/Logs';

interface LogStore {
  logObject: LogsRequestI;
  updateLogStore: (obj: Partial<LogsRequestI>) => void;
  filtersValuesDefault: FiltersLogsInterface;
  filtersValuesChange: FiltersLogsInterface;

  loading: boolean;
  updateLoading: (value: boolean) => void;

  updateFiltersChange: (obj: Partial<FiltersLogsInterface>) => void;
  updateFiltersDefault: (obj: FiltersLogsInterface) => void;
}

const useLogStore = create<LogStore>((set, get) => ({
  loading: false,
  updateLoading: value => set({ loading: value }),
  logObject: defaultLogs,
  updateLogStore: obj => {
    set(state => ({
      logObject: { ...state.logObject, ...obj },
    }));
  },

  filtersValuesDefault: filtersLogsDefault,
  filtersValuesChange: filtersLogsDefault,

  updateFiltersChange: obj => {
    set(state => ({
      filtersValuesChange: { ...state.filtersValuesChange, ...obj },
    }));
  },

  updateFiltersDefault: obj => set({ filtersValuesDefault: obj }),
}));

export default useLogStore;
