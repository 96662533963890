import React, { useContext, useEffect, useRef, useState } from 'react';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import InfoIcon from '@mui/icons-material/Info';
import {
  Box,
  IconButton,
  Link,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import relativetime from 'dayjs/plugin/relativeTime';
import {
  FlexBox,
  FlexBoxBetween,
  TableCellCustom,
} from '../../../components/tableItems';
import {
  defaultLogs,
  getEventDisplayName,
  logsHeadCell,
  logsHeadCellAdmin,
  LogsObjectI,
} from '../../../models/Logs';
import { fetchLogs, rescheduleLog } from '../../../helpers/historyHelper';
import TableWrapper from '../../../components/Table/TableWrapper';
import { HeadCell } from '../../../models/Table';
import { Order, SeverityType } from '../../../models/General';
import { StyledTableRow } from '../../../components/LinksTable/LinksTable';
import useLogStore from '../../../stores/history/log';
import ScheduleSend from '../../DefaultView/LinkForm/ScheduleSend';
import { getTimeFrames } from '../../../helpers/links';
import FeedbackMessage from '../../../components/FeedbackMessage';
import Context from '../../../context/UserContext';
import { UserContextType } from '../../../models/User';
import { IS_USER_ADMIN } from '../../../constants/user';

const LogsTable = () => {
  dayjs.extend(relativetime);
  const {
    logObject,
    updateLogStore,
    updateLoading,
    loading: loadingLogs,
  } = useLogStore();
  const { UserContext } = useContext(Context) as UserContextType;

  const isFirstRender = useRef(true);

  const [logs, setLogs] = useState<LogsObjectI[]>([]);
  const [error, setError] = useState(false);

  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState<{
    message: string;
    severity: SeverityType;
  }>({ message: '', severity: 'success' });

  const [scheduleSendModal, setScheduleSendModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTimeBlock, setSelectedTimeBlock] = useState('');
  const [timeFrames, setTimeFrames] = useState<
    {
      block_title: string;
      block_start: string;
      scheduled_messages: number;
      is_full: false;
    }[]
  >([]);
  const [loadingReschedule, setLoadingReschedule] = useState(false);
  const [logId, setLogId] = useState(0);

  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<string>('created');
  const [countLogs, setCountLogs] = useState(0);

  const fetchLogsResponse = async () => {
    setError(false);
    updateLoading(true);
    try {
      const response = await fetchLogs(logObject);
      setLogs(response.results);
      setCountLogs(response.count);
    } catch (err) {
      setLogs([]);
      setError(true);
    } finally {
      updateLoading(false);
    }
  };

  const handleRequestSort = (property: string) => {
    const isSame = orderBy === property;
    let orderRequest = order;
    if (isSame) {
      orderRequest = order === 'asc' ? 'desc' : 'asc';
      setOrder(order === 'asc' ? 'desc' : 'asc');
    }
    setOrderBy(property);
    updateLogStore({
      ordering: `${orderRequest === 'desc' ? '-' : ''}${property}`,
    });
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    fetchLogsResponse();
  }, [logObject]);

  useEffect(() => {
    return () => {
      setLogs([]);
      setError(false);
      updateLoading(false);
      updateLogStore(defaultLogs);
    };
  }, []);

  const updatePage = (event: unknown, newPage: number) => {
    updateLogStore({ page: newPage });
    window.scrollTo(0, 0);
  };

  const updatePageSize = (value: number) => {
    updateLogStore({ page: 1, page_size: value });
    window.scrollTo(0, 0);
  };

  const handleReschedule = async () => {
    setLoadingReschedule(true);
    try {
      const dateTransformed = dayjs(selectedDate).format('YYYY-MM-DD');
      const currentHour = dayjs().format('HH:mm:ss');
      const newScheduledDate = selectedTimeBlock
        ? `${dateTransformed} ${selectedTimeBlock}`
        : `${dateTransformed} ${currentHour}`;

      await rescheduleLog({
        message_id: logId,
        new_scheduled_date: newScheduledDate,
      });
      setFeedbackMessage({
        message: 'Message rescheduled',
        severity: 'success',
      });
      setFeedbackOpen(true);
      setTimeFrames([]);
      setSelectedDate('');
      setSelectedTimeBlock('');
      setScheduleSendModal(false);
      setLogId(0);
      fetchLogsResponse();
    } catch (err) {
      const { data } = err as any;

      setFeedbackMessage({
        message: data.Result,
        severity: 'error',
      });
      setFeedbackOpen(true);
      console.log(err);
    } finally {
      setLoadingReschedule(false);
    }
  };

  const getBlockTimes = async () => {
    setTimeFrames([]);
    setSelectedTimeBlock('');
    try {
      const dateNow = dayjs(selectedDate).format('YYYY-MM-DD');
      const resp = await getTimeFrames(dateNow);
      setTimeFrames(resp.blocks);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (selectedDate === '') {
      setTimeFrames([]);
      setSelectedTimeBlock('');
    } else getBlockTimes();
  }, [selectedDate]);

  const returnLogsHead = () =>
    IS_USER_ADMIN.includes(UserContext.profile.role)
      ? logsHeadCellAdmin
      : logsHeadCell;

  return (
    <>
      <TableWrapper
        loading={loadingLogs}
        error={error}
        type="Logs"
        totalCells={
          IS_USER_ADMIN.includes(UserContext.profile.role)
            ? logsHeadCellAdmin.length - 1
            : logsHeadCell.length - 1
        }
        fetchInfo={fetchLogsResponse}
        itemsLength={logs.length}
        countItems={countLogs}
        pageSize={logObject.page_size}
        page={logObject.page}
        showCount
        handleChangePage={updatePage}
        handleChangeRowsPerPage={updatePageSize}
        HeadOfTable={
          <TableHead sx={{ borderTop: '1px solid rgba(224, 224, 224, 1)' }}>
            <TableRow>
              {returnLogsHead().map(headCell => (
                <TableCell
                  key={headCell.id}
                  sx={{
                    minWidth: 80,
                    color: 'black',
                    fontSize: 12,
                    fontWeight: 'bold',
                  }}
                  align="left"
                  className={headCell.main ? 'first_column-log' : ''}
                  padding={headCell.disablePadding ? 'none' : 'normal'}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  {headCell.main ? (
                    <Box>
                      <TableSortLabel
                        sx={{ paddingLeft: 1.5 }}
                        active={orderBy === headCell.id}
                        direction={order}
                        onClick={() => {
                          handleRequestSort(headCell.id);
                        }}
                      >
                        {headCell.label}
                      </TableSortLabel>
                    </Box>
                  ) : (
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      disabled={headCell.disableSort}
                      direction={order}
                      onClick={() => {
                        handleRequestSort(headCell.id);
                      }}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        }
      >
        {!loadingLogs && logs.length > 0 && (
          <>
            {logs.map((log, index) => (
              <StyledTableRow
                hover
                role="checkbox"
                key={log.id}
                className="column-row"
              >
                <TableCell
                  className="first_column"
                  component="th"
                  sx={{ backgroundColor: index % 2 === 0 ? '#f5f5f5' : '' }}
                  id={`log-${log.id}`}
                  scope="row"
                  padding="none"
                >
                  <FlexBoxBetween
                    sx={{
                      color: '#373737',
                      paddingTop: 2.3,
                      paddingBottom: 2.3,
                      paddingRight: 1,
                      paddingLeft: 1,
                      width: '100%',
                    }}
                  >
                    {log.link && (
                      <Link
                        href={log.link.url ?? '-'}
                        target="_blank"
                        color="#373737"
                        underline="hover"
                        className="logs-link_text"
                      >
                        {log.link.url ?? '-'}
                      </Link>
                    )}
                    {!log.link && (
                      <Typography color="#373737" className="logs-link_text">
                        -
                      </Typography>
                    )}

                    <Box sx={{ mr: 1 }}>
                      <Tooltip title="Reschedule">
                        {log.conversation_message &&
                        (log.conversation_message.send_status === 'queued' ||
                          log.conversation_message.send_status ===
                            'scheduled') ? (
                          <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-haspopup="true"
                            disabled={false}
                            onClick={() => {
                              setScheduleSendModal(true);
                              setLogId(log.conversation_message?.id ?? 0);
                            }}
                          >
                            <ScheduleSendIcon
                              sx={{
                                color: '#84D0F0',
                                '&:hover': { color: '#84D0F0' },
                              }}
                            />
                          </IconButton>
                        ) : (
                          <div />
                        )}
                      </Tooltip>
                    </Box>
                  </FlexBoxBetween>
                </TableCell>
                <TableCellCustom>
                  {log.created ? dayjs(log.created).format('MM-DD-YYYY') : ''}
                </TableCellCustom>

                <TableCellCustom>
                  {log.created ? dayjs(log.created).format('hh:mm A') : ''}
                </TableCellCustom>

                {/* <TableCellCustom>
                  {log.note && (
                    <FlexBox>
                      {log.note.length > 30
                        ? `${log.note.slice(0, 30)}...`
                        : log.note}
                      {log.note.length > 30 && (
                        <Tooltip title={log.note}>
                          <IconButton>
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </FlexBox>
                  )}
                  {!log.note && '-'}
                </TableCellCustom> */}
                <TableCellCustom>
                  <FlexBox>
                    {getEventDisplayName(log.event_type ?? '')}
                    {log.note && (
                      <Tooltip title={log.note}>
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </FlexBox>
                </TableCellCustom>
                <TableCellCustom>
                  {log.contact ? log.contact.email : '-'}
                </TableCellCustom>
                <TableCellCustom>
                  {log.status_update_note ?? '-'}
                </TableCellCustom>
                {IS_USER_ADMIN.includes(UserContext.profile.role) && (
                  <TableCellCustom>{log.owner.username}</TableCellCustom>
                )}
              </StyledTableRow>
            ))}
          </>
        )}
      </TableWrapper>
      <ScheduleSend
        open={scheduleSendModal}
        send={handleReschedule}
        loading={loadingReschedule}
        timeFrames={timeFrames}
        closeModal={(value: boolean) => setScheduleSendModal(value)}
        selectedDate={selectedDate}
        selectedTimeBlock={selectedTimeBlock}
        updateSelectedTime={(value: string) => setSelectedTimeBlock(value)}
        updateSelectedDate={(value: string) => setSelectedDate(value)}
      />

      <FeedbackMessage
        open={feedbackOpen}
        setOpen={setFeedbackOpen}
        vertical="top"
        horizontal="center"
        severity={feedbackMessage.severity}
        message={feedbackMessage.message}
      />
    </>
  );
};

export default LogsTable;
