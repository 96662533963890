/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  Box,
  Chip,
  FormControlLabel,
  IconButton,
  Skeleton,
  styled,
  Switch,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import DownloadIcon from '@mui/icons-material/Download';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import dayjs from 'dayjs';
import relativetime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import EnhancedTable from '../../../components/LinksTable/LinksTable';
import { ClaimsInt, LinkObject } from '../../../models/Links';
import TabPanel from '../../../components/Tab/TabPanel';
import GeneralContext from '../../../context/GeneralActions';
import { GlobalContextType, LoadingContextType } from '../../../models/Context';
import { VisibleColumnsInterface } from '../../../models/Filters';
import RightColumns from '../../../components/Columns/RightColumns';
import MainDrawer from '../../../components/Drawers/MainDrawer';
import AddLink from '../../../components/Forms/Links/AddLink';
import TabParent from '../../../components/Tab/TabParent';
import {
  ContactsArrayOutreach,
  EditObjectLink,
  ObjectLinks,
  TemplateLink,
  formObjectLink,
  formTemplateLink,
} from '../../../models/Forms';
import FeedbackMessage from '../../../components/FeedbackMessage';
import { SeverityType } from '../../../models/General';
import useLinks from '../../../customHooks/useLinks';
import { sendBackObject } from '../../../helpers/helpersfunctions';
import {
  IS_USER_ADMIN,
  IS_USER_ADMIN_AND_STRAT,
  IS_USER_ADMIN_MANAGER_STRAT,
  ROLES_LINKS,
} from '../../../constants/user';
import Context from '../../../context/UserContext';
import { UserContextType } from '../../../models/User';
import {
  fetchProspectsUser,
  fetchProspectsUserNotes,
} from '../../../helpers/prospecting';
import { UserLinksResult } from '../../../models/Prospecting';
import ProspectingEligibleTable from '../../Prospecting/ProspectingEligibleTable';
import LoadingContext from '../../../context/LoadingContext';
import Claims from './Claims';
import {
  claimCounts,
  downloadAllClaims,
  downloadAllClaimsMaster,
  getAllClaims,
} from '../../../helpers/claims';
import MasterLinks from './MasterLinks';
import ConfirmEmail from '../../../components/Forms/Links/ConfirmEmail';
import {
  countLink,
  downloadAllLinks,
  fetchAllContacts,
  updateLink,
} from '../../../helpers/links';
import { TemplateAudience } from '../../../models/Templates';
import DeleteModalLinks from '../../../components/Modals/DeleteModalLinks';
import UnclaimedTab from './Unclaimed';
import useLinksUnclaimed from '../../../customHooks/useLinksUnclaimed';
import useAssignedToMeCount from '../../../customHooks/useAssignedToMeCount';
import SearchInput from '../../../components/Search/SearchInput';
import { MainButton } from '../../../components/buttons';
import useFiltersLinks from '../../../stores/filters/useFiltersLinks';
import useFiltersClaims from '../../../stores/filters/useFiltersClaims';
import useFiltersMaster from '../../../stores/filters/useFiltersMaster';
import useFiltersUnclaimed from '../../../stores/filters/useFiltersUnclaimed';
import useFiltersAssigned from '../../../stores/filters/useFiltersAssigned';
import { FlexBox, FlexBoxBetween } from '../../../components/tableItems';
import { LinkStatuses } from '../../../models/Status';
import useBuildOutreach from '../../../customHooks/useBuildOutreach';
import useDomainCounter from '../../../customHooks/useDomainCounter';
import useUnsubscribeCount from '../../../customHooks/useUnsubscribeCount';
import useSidebar from '../../../stores/links/useSidebar';
import AddWarningLink from '../AddWarningLink';
import useLinkForm from '../../../stores/links/useLinkForm';
import { LinkFormDefault, ObjEditFormLink } from '../../../models/LinkForm';
import NewPagination from '../PaginationTables/NewPagination';

type CountObjectType = {
  amount_remaining: number;
  percentage_remaining: number;
  previous_month_total: number;
  counter_links_dupulicated_current_month: number;
  user_percentage_allowed: null | number;
  site_default_percentage: number;
};

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 350,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

interface Props {
  columnsVisibility: VisibleColumnsInterface;
  updateTab: (value: number, prop: boolean) => void;
  statusOfLinks: LinkStatuses[];
  updateFormDrawer: () => void;
}

const tabsArrDefault = [
  { id: 0, label: 'All', disabled: false },
  { id: 1, label: 'Unclaimed', disabled: false },
  { id: 2, label: 'Claims', disabled: false },
];

const tabsArrAdmin = [
  { id: 0, label: 'All', disabled: false },
  { id: 1, label: 'Unclaimed', disabled: false },
  { id: 2, label: 'Claims', disabled: false },
  { id: 3, label: 'MLL', disabled: false },
];

const contactObjectDefault: ContactsArrayOutreach = {
  id: 0,
  name: '',
  email: '',
  is_form: false,
  status: 'open',
  is_default: false,
  validation_status: 'active',
};

const canEditLinks = [
  'administrator',
  'dev admin',
  'strategist',
  'manager',
  'account manager',
];

const Links = ({
  columnsVisibility,
  updateTab,
  statusOfLinks,
  updateFormDrawer,
}: Props) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(relativetime);
  const { formSidebar, updateSidebar, updateAvailable } = useSidebar();
  const {
    replaceLinkForm,
    replaceObjEditForm,
    resetPendingListObject,
    updateTabForm,
    linkForm,
    updateLoadingForm,
    updateClaimId,
  } = useLinkForm();
  const currentMonth = dayjs().format('MMMM');
  const filtersLinks = useFiltersLinks();
  const filterAssigned = useFiltersAssigned();
  const filterClaims = useFiltersClaims();
  const filterMaster = useFiltersMaster();
  const filterUnclaimed = useFiltersUnclaimed();

  const [modalWarningLink, setModalWarningLink] = useState(false);
  const updateModalWarningLink = (value: boolean) => setModalWarningLink(value);

  const [warningEditLink, setModalWarningEditLink] = useState(false);
  const updateModalWarningEditLink = (value: boolean) =>
    setModalWarningEditLink(value);

  const [warningCreate, setModalWarningCreate] = useState(false);
  const updateWarningCreate = (value: boolean) => setModalWarningCreate(value);

  const [valueTab, setValueTab] = useState<any>(undefined);

  const [searchContacts, setSearchContacts] = useState(false);

  const { resultsBuild, loading: loadingBuild } = useBuildOutreach();
  const {
    countResult: countUnsubscribe,
    loading: loadingCountUnsubscribe,
    previousMonthResult,
  } = useUnsubscribeCount();
  const { countResult: countDomain, loading: loadingCountDomain } =
    useDomainCounter();
  const [isLoadingCount, setIsLoadingCount] = useState(false);
  const [countObject, setCountObject] = useState<CountObjectType>({
    amount_remaining: 0,
    percentage_remaining: 0,
    previous_month_total: 0,
    counter_links_dupulicated_current_month: 0,
    user_percentage_allowed: null,
    site_default_percentage: 0,
  });

  const abortControllerLink = useRef<AbortController | null>(null);
  const abortControllerAssignedMe = useRef<AbortController | null>(null);
  const abortControllerUnclaimed = useRef<AbortController>(null);
  const abortControllerClaims = useRef<AbortController | null>(null);
  const abortControllerMaster = useRef<AbortController | null>(null);
  const { countAssignedToMe, loadingCount, getCount } = useAssignedToMeCount();
  const [openModalOutreach, setOpenModalOutreach] = useState(false);
  const { UserContext } = useContext(Context) as UserContextType;
  const { GlobalContext, setGlobalContext } = useContext(
    GeneralContext,
  ) as GlobalContextType;
  const [tab, setTab] = useState(0);
  const [deleteLink, setDeleteLink] = useState(0);
  const [modalDelete, setModalDelete] = useState(false);
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState<{
    message: string;
    severity: SeverityType;
  }>({ message: '', severity: 'success' });
  const [render, setRender] = useState(0);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [drawerOption, setdrawerOption] = useState(0);

  const { links, loading, error, refreshLinks, count, contacts } = useLinks({
    abortLinks: abortControllerLink,
  });

  const {
    links: linksUnclaimed,
    loading: loadingUnclaimed,
    error: errorUnclaimed,
    refreshLinks: refreshLinksUnclaimed,
    count: countLinksUnclaimed,
    contacts: contactsLinksUnclaimed,
  } = useLinksUnclaimed({ abortLinks: abortControllerUnclaimed });

  const [objectEditLink, setObjectEditLink] = useState<{
    obj: ObjectLinks;
    temp: TemplateLink;
    modified: string;
    tab: number;
    aud: TemplateAudience;
    aff: TemplateAudience;
    siteTemp: { id: number; name: string };
    openNewContact: boolean;
    is_older_than_thirty_days: boolean;
    is_older_than_ninety_days: boolean;
    unsubscribed_date: string;
    is_unsubscribed: boolean;
  }>({
    obj: formObjectLink,
    temp: formTemplateLink,
    modified: '',
    tab: 0,
    aud: { id: 0, name: '', status: '' },
    aff: { id: 0, name: '', status: '' },
    siteTemp: { id: 0, name: '' },
    openNewContact: false,
    is_older_than_ninety_days: false,
    is_older_than_thirty_days: false,
    unsubscribed_date: '',
    is_unsubscribed: false,
  });
  const [sendEmailObject, setSendEmailObject] = useState<{
    obj: ObjectLinks;
    loading: boolean;
  }>({ obj: formObjectLink, loading: false });

  const [linksUser, setLinksUser] = useState<UserLinksResult[]>([]);
  const [notesLinksUser, setNotesLinksUser] = useState<number[]>([]);
  const [linksUserCount, setLinksUserCount] = useState(0);
  const [isLoadingUserLinks, setIsLoadingUserLinks] = useState(false);
  const [isError, setIsError] = useState(false);
  const { isLoadingContext, setIsLoading } = useContext(
    LoadingContext,
  ) as LoadingContextType;

  const [claimsLinks, setClaimsLinks] = useState<ClaimsInt[]>([]);
  const [isLoadingClaims, setIsLoadingClaims] = useState(false);
  const [isErrorClaims, setIsErrorClaims] = useState(false);
  const [claimsCount, setClaimsCount] = useState({
    total_points_sum: 0,
    results_count: 0,
    count: 0,
  });

  const [masterLinks, setMasterLinks] = useState<ClaimsInt[]>([]);
  const [isLoadingMaster, setIsLoadingMaster] = useState(false);
  const [isErrorMaster, setIsErrorMaster] = useState(false);
  const [masterCount, setMasterCount] = useState({
    total_points_sum: 0,
    results_count: 0,
    count: 0,
  });

  const [linkToEdit, setLinkToEdit] = useState<{
    link?: LinkObject;
    tab?: number;
    statusLink: string;
  }>({ link: undefined, tab: 0, statusLink: '' });

  const showMessage = (message: string, severity: SeverityType) => {
    setFeedbackMessage({ message, severity });
    setFeedbackOpen(true);
  };

  const getUserLinks = async () => {
    setIsError(false);
    setIsLoading({ loading: true });
    setIsLoadingUserLinks(true);
    setLinksUser([]);
    setLinksUserCount(0);
    abortControllerAssignedMe.current = new AbortController();
    try {
      const resp = await fetchProspectsUser(
        GlobalContext.assigned_me.query_filters,
        GlobalContext.assigned_me.page,
        GlobalContext.assigned_me.page_size,
        GlobalContext.site_selected,
        GlobalContext.assigned_me.order,
        abortControllerAssignedMe.current.signal,
      );
      if (
        resp.results.filter(item => item.pending_link_id !== null).length > 0
      ) {
        const newResponse = await Promise.all(
          resp.results.map(async item => {
            if (
              item.pending_link_id === null ||
              item.pending_link_id === undefined
            )
              return 0;
            try {
              const numberNotes = await fetchProspectsUserNotes(
                item.pending_link_id,
              );
              return numberNotes;
            } catch (err) {
              return 0;
            }
          }),
        );
        setNotesLinksUser(newResponse);
      } else {
        setNotesLinksUser(new Array(resp.results.length).fill(0));
      }
      setLinksUser(resp.results);
      setLinksUserCount(resp.count);
      setIsLoadingUserLinks(false);
      setIsLoading({ loading: false });
    } catch (err) {
      if (err) {
        setIsError(true);
        setIsLoadingUserLinks(false);
        setIsLoading({ loading: false });
      }
      setLinksUser([]);
      setLinksUserCount(0);
      setNotesLinksUser([]);
    }
  };

  const fetchClaims = async () => {
    abortControllerClaims.current = new AbortController();
    setClaimsLinks([]);
    setIsLoadingClaims(true);
    setIsLoading({ loading: true });
    setIsErrorClaims(false);
    try {
      const resp = await getAllClaims(
        GlobalContext.claims.order,
        GlobalContext.claims.query_filters,
        GlobalContext.claims.page,
        GlobalContext.claims.page_size,
        abortControllerClaims.current.signal,
      );
      setClaimsLinks(resp.results);

      const respCount = await claimCounts(
        GlobalContext.claims.query_filters.replace('&', '?'),
      );

      setClaimsCount({
        count: resp.count,
        total_points_sum: respCount.total_points_sum,
        results_count: respCount.results_count,
      });
      setIsLoadingClaims(false);
      setIsLoading({ loading: false });
    } catch (err) {
      if (err) {
        setIsError(true);
        setIsLoadingClaims(false);
        setIsLoading({ loading: false });
      }
      setClaimsLinks([]);
    }
  };

  const fetchMaster = async () => {
    abortControllerMaster.current = new AbortController();
    setIsLoadingMaster(true);
    setIsLoading({ loading: true });
    setIsErrorMaster(false);
    try {
      const resp = await getAllClaims(
        GlobalContext.master.order,
        GlobalContext.master.query_filters,
        GlobalContext.master.page,
        GlobalContext.master.page_size,
        abortControllerMaster.current.signal,
      );
      setMasterLinks(resp.results);

      const respCount = await claimCounts(
        GlobalContext.master.query_filters.replace('&', '?'),
      );
      setMasterCount({
        count: resp.count,
        total_points_sum: respCount.total_points_sum,
        results_count: respCount.results_count,
      });
      setIsLoadingMaster(false);
      setIsLoading({ loading: false });
    } catch (err) {
      if (err) {
        setIsError(true);
        setIsLoadingMaster(false);
        setIsLoading({ loading: false });
      }
      setMasterLinks([]);
    }
  };

  const downloadContent = async () => {
    abortControllerMaster.current = new AbortController();
    setIsLoading({ loading: true });
    try {
      await downloadAllClaimsMaster(
        GlobalContext.master.order,
        GlobalContext.master.query_filters,
        GlobalContext.master.page,
        GlobalContext.master.page_size,
        abortControllerMaster.current.signal,
      );
      showMessage('Export file will be sent to your email', 'success');
      setIsLoading({ loading: false });
    } catch (err) {
      const errMessage = err as any;
      showMessage(errMessage.data, 'error');

      if (err) {
        setIsLoading({ loading: false });
      }
    }
  };

  const downloadContentClaims = async () => {
    abortControllerClaims.current = new AbortController();
    setIsLoading({ loading: true });
    try {
      await downloadAllClaims(
        GlobalContext.claims.order,
        GlobalContext.claims.query_filters,
        GlobalContext.claims.page,
        GlobalContext.claims.page_size,
        abortControllerClaims.current.signal,
      );
      showMessage('Export file will be sent to your email', 'success');
      setIsLoading({ loading: false });
    } catch (err) {
      const errMessage = err as any;
      showMessage(errMessage.data, 'error');

      if (err) {
        setIsLoading({ loading: false });
      }
    }
  };

  const downloadContentLinks = async () => {
    abortControllerLink.current = new AbortController();
    setIsLoading({ loading: true });
    try {
      await downloadAllLinks(
        GlobalContext.links.order,
        GlobalContext.links.query_filters,
        GlobalContext.links.page,
        GlobalContext.links.page_size,
        abortControllerLink.current.signal,
      );
      showMessage('Export file will be sent to your email', 'success');
      setIsLoading({ loading: false });
    } catch (err) {
      const errMessage = err as any;
      showMessage(errMessage.data, 'error');

      if (err) {
        setIsLoading({ loading: false });
      }
    }
  };

  const cancelRequestLinks = () =>
    abortControllerLink.current && abortControllerLink.current.abort();

  const cancelRequestClaims = () =>
    abortControllerClaims.current && abortControllerClaims.current.abort();

  const cancelRequestMaster = () =>
    abortControllerMaster.current && abortControllerMaster.current.abort();

  const cancelRequestUnclaimed = () =>
    abortControllerUnclaimed.current &&
    abortControllerUnclaimed.current.abort();

  const cancelRequestAssignedMe = () =>
    abortControllerAssignedMe.current &&
    abortControllerAssignedMe.current.abort();

  useEffect(() => {
    if (render !== 0 && tab === 0) {
      cancelRequestLinks();
      cancelRequestUnclaimed();
      cancelRequestClaims();
      cancelRequestMaster();
      cancelRequestAssignedMe();
      refreshLinks();
    }
  }, [GlobalContext.links]);

  useEffect(() => {
    if (render !== 0 && tab === 2) {
      cancelRequestLinks();
      cancelRequestAssignedMe();
      cancelRequestClaims();
      cancelRequestMaster();
      cancelRequestUnclaimed();
      fetchClaims();
    }
  }, [GlobalContext.claims]);

  useEffect(() => {
    if (render !== 0 && tab === 1) {
      cancelRequestLinks();
      cancelRequestAssignedMe();
      cancelRequestClaims();
      cancelRequestMaster();
      cancelRequestUnclaimed();
      refreshLinksUnclaimed();
    }
  }, [GlobalContext.unclaimed]);

  useEffect(() => {
    if (render !== 0 && tab === 3) {
      cancelRequestLinks();
      cancelRequestMaster();
      cancelRequestClaims();
      cancelRequestUnclaimed();
      cancelRequestAssignedMe();
      fetchMaster();
    }
  }, [GlobalContext.master]);

  // useEffect(() => {
  //   if (render !== 0 && tab === 1) {
  //     cancelRequestAssignedMe();
  //     cancelRequestUnclaimed();
  //     cancelRequestMaster();
  //     cancelRequestClaims();
  //     cancelRequestLinks();
  //     if (GlobalContext.site_selected !== 0) {
  //       getUserLinks();
  //     } else {
  //       setLinksUser([]);
  //       setLinksUserCount(0);
  //     }
  //   }
  // }, [GlobalContext.assigned_me]);

  // useEffect(() => {
  //   if (GlobalContext.site_selected !== 0) getCount();
  //   if (render !== 0 && tab === 1) {
  //     setGlobalContext({
  //       ...GlobalContext,
  //       assigned_me: {
  //         ...GlobalContext.assigned_me,
  //         page: 1,
  //         page_size: 10,
  //       },
  //     });
  //   }
  // }, [GlobalContext.site_selected]);

  useEffect(() => {
    if (GlobalContext.site_selected !== 0) getCount();
    updateTab(tab, true);
  }, [tab]);

  useEffect(() => {
    setRender(1);
    return () => {
      setGlobalContext({
        ...GlobalContext,
        links: {
          order: '-created',
          page: 1,
          page_size: 50,
          query_filters: '',
          search_filter: '',
        },
        unclaimed: {
          order: '-created',
          page: 1,
          page_size: 10,
          query_filters: '',
          search_filter: '',
        },
        claims: {
          order: 'modified',
          query_filters: '',
          page: 1,
          page_size: 10,
        },
        master: {
          order: '-date_approved',
          query_filters: '',
          page: 1,
          page_size: 10,
        },
        assigned_me: {
          page: 1,
          page_size: 10,
          query_filters: '',
          order: '-created',
        },
      });
    };
  }, []);

  const openAddLink = () => {
    setObjectEditLink({
      obj: formObjectLink,
      temp: formTemplateLink,
      modified: '',
      tab: 0,
      aud: { id: 0, name: '', status: '' },
      aff: { id: 0, name: '', status: '' },
      siteTemp: { id: 0, name: '' },
      openNewContact: false,
      is_older_than_ninety_days: false,
      is_older_than_thirty_days: false,
      is_unsubscribed: false,
      unsubscribed_date: '',
    });
    setdrawerOption(1);
    setOpenDrawer(true);
  };

  const findContacts = (id: number, unclaimed?: boolean) => {
    if (unclaimed) {
      const contactsLink = contactsLinksUnclaimed.filter(
        contact => contact.id === id,
      );
      return contactsLink[0].contacts_links.length === 0
        ? [contactObjectDefault]
        : contactsLink[0].contacts_links;
    }
    const contactsLink = contacts.filter(contact => contact.id === id);
    return contactsLink[0].contacts_links.length === 0
      ? [contactObjectDefault]
      : contactsLink[0].contacts_links;
  };

  const validateLinkEdit = (
    owner: number,
    site_id: number,
    modified_date: string,
  ) => {
    const RoleEdit = sendBackObject(UserContext.profile.role, ROLES_LINKS).edit;
    if (!RoleEdit) {
      return site_id;
    }

    if (canEditLinks.includes(UserContext.profile.role)) return site_id;

    if (owner === UserContext.id) return GlobalContext.site_selected;

    const diffMilSeconds = Math.abs(+new Date(modified_date) - +new Date());
    const diffDays = Math.ceil(diffMilSeconds / (1000 * 60 * 60 * 24));
    if (diffDays < 90) {
      if (owner !== UserContext.id) return site_id;
    } else if (
      UserContext.id !== owner &&
      !canEditLinks.includes(UserContext.profile.role)
    ) {
      return GlobalContext.site_selected;
    }

    return site_id;
  };

  const validateValues = (link: LinkObject) => {
    const object = { audience: 0, affinity: 0, template: 0 };
    if (link.audience) {
      if (link.audience.status === 'inactive') return object;
      object.audience = link.audience.id;
    }
    if (link.audience && link.audience.status === 'active')
      object.audience = link.audience.id;

    if (link.affinity) {
      if (link.affinity.status === 'inactive') return object;
      object.affinity = link.affinity.id;
    }

    if (link.template) {
      if (link.template.status === 'inactive') return object;
      object.template = link.template.id;
    }

    return object;
  };

  // const editArrContacts = (arr: ContactsArrayOutreach[]) => {
  //   const newArr = [...arr];
  //   if (newArr.findIndex(item => !item.is_form) !== -1) {
  //     const numberIndex = newArr.findIndex(item => !item.is_form);
  //     newArr[numberIndex].is_default = true;
  //   }
  //   return newArr;
  // };

  const getContactsFromLink = async (id: number) => {
    try {
      const contactsLink = await fetchAllContacts([id]);
      return contactsLink[0].contacts_links.length === 0
        ? [contactObjectDefault]
        : contactsLink[0].contacts_links;
    } catch (err) {
      return [contactObjectDefault];
    }
  };

  const followUpEdit = async (
    link: LinkObject,
    statusLink: string,
    tabEdit?: number,
    unclaimed?: boolean,
    openNewContactModal?: boolean,
  ) => {
    updateLoadingForm(true);
    updateSidebar({ type: 'read' });
    resetPendingListObject();
    const validationOfSite = validateLinkEdit(
      link.owner.id,
      link.site.id,
      link.modified,
    );

    console.log(openNewContactModal);

    const newValues = validateValues(link);

    const contactsFind = await getContactsFromLink(link.id);

    const contactsArr = contactsFind
      ? contactsFind.map(item => {
          const copyObj = { ...item };
          if (copyObj.close_reason === null) delete copyObj.close_reason;
          return {
            ...copyObj,
            is_default:
              item.validation_status === 'inactive' ||
              item.status === 'site_closed' ||
              item.is_form
                ? false
                : item.is_default ?? false,
          };
        })
      : [];

    const obj: ObjectLinks = {
      id: link.id,
      url: link.url,
      site: validationOfSite,
      found_date: link.found,
      audience: validationOfSite !== link.site.id ? 0 : newValues.audience,
      affinity: validationOfSite !== link.site.id ? 0 : newValues.affinity,
      contacts: contactsArr,
      status: statusLink.toLowerCase(),
      is_cannibalization: link.is_cannibalization,
      owner: link.owner.id,
      template: validationOfSite !== link.site.id ? 0 : newValues.template,
      assignee: link.owner.id,
    };

    // const aud = {
    //   id: link.audience ? link.audience.id : 0,
    //   name: link.audience ? link.audience.name : '',
    //   status: link.audience ? link.audience.status : 'active',
    // };
    // const aff = {
    //   id: link.affinity ? link.affinity.id : 0,
    //   name: link.affinity ? link.affinity.name : '',
    //   status: link.affinity ? link.affinity.status : 'active',
    // };
    // const temp: TemplateLink = {
    //   id: link.template ? link.template.id : 0,
    //   title: link.template ? link.template.title : '',
    //   greetings: '',
    //   automatic_greeting: true,
    //   signoff: '',
    //   audience: link.audience ? link.audience : { id: 0, name: '', status: '' },
    //   affinity: link.affinity ? link.affinity : { id: 0, name: '', status: '' },
    //   subject: '',
    //   body: '',
    //   status: '',
    //   created: '',
    //   modified: '',
    //   site: { id: 0, name: '', status: '' },
    //   owner: { id: 0, username: '', email: '' },
    //   status_notes: [],
    //   general_notes: [],
    // };

    // const siteTemp = {
    //   id: link.site.id,
    //   name: link.site.name,
    // };

    // replaceLinkForm(obj);
    const dateNow = dayjs();
    const modDate = dayjs(link.modified);
    const differenceInDays = dateNow.diff(modDate, 'day');

    replaceObjEditForm(
      {
        ...obj,
        site_name: link.site.name,
        is_older_than_ninety_days: link.is_older_than_ninety_days,
        is_older_than_thirty_days: link.is_older_than_thirty_days,
        is_unsubscribed: link.is_unsubscribed,
        unsubscribed_date: link.unsubscribed_date,
        added_date: link.created,
        modified_date: link.modified,
        openNewContactModal:
          link.status === 'invalid_contact' && differenceInDays > 7,
      },
      obj,
      IS_USER_ADMIN.includes(UserContext.profile.role),
    );
    updateTabForm(tabEdit ?? 0);
    // setObjectEditLink({
    //   obj,
    //   temp,
    //   modified: link.modified,
    //   tab: tabEdit ?? 0,
    //   aud,
    //   aff,
    //   siteTemp,
    //   openNewContact: openNewContactModal ?? false,
    //   is_older_than_ninety_days: link.is_older_than_ninety_days,
    //   is_older_than_thirty_days: link.is_older_than_thirty_days,
    //   is_unsubscribed: link.is_unsubscribed,
    //   unsubscribed_date: link.unsubscribed_date,
    // });
    // setdrawerOption(2);
    // setOpenDrawer(true);
  };
  const editLink = (
    link: LinkObject,
    statusLink: string,
    tabEdit?: number,
    unclaimed?: boolean,
    openNewContactModal?: boolean,
  ) => {
    if (linkForm.id && linkForm.id !== 0 && formSidebar.type === 'edit') {
      setLinkToEdit({ link, statusLink, tab: tabEdit });
      updateModalWarningEditLink(true);
    } else {
      followUpEdit(link, statusLink, tabEdit, unclaimed, openNewContactModal);
    }
  };
  const sendNewEmail = async () => {
    setSendEmailObject({ ...sendEmailObject, loading: true });
    try {
      updateLink(sendEmailObject.obj, sendEmailObject.obj.id ?? 0);
      showMessage(`Email sent successfully`, 'success');
      refreshLinks();
    } catch (err) {
      showMessage(`Error sending email`, 'error');
    } finally {
      setSendEmailObject({ ...sendEmailObject, loading: false });
      setOpenModalOutreach(false);
    }
  };

  const handleSendEmail = (
    link: LinkObject,
    statusLink: string,
    unclaimed?: boolean,
  ) => {
    const obj: ObjectLinks = {
      id: link.id,
      url: link.url,
      site: link.site.id,
      is_cannibalization: link.is_cannibalization,
      audience: link.audience ? link.audience.id : 0,
      affinity: link.affinity ? link.affinity.id : 0,
      contacts: findContacts(link.id, unclaimed),
      send_email: true,
      status: statusLink.toLowerCase(),
      owner: link.owner.id,
      template: link.template.id,
      assignee: link.owner.id,
    };
    setSendEmailObject({ obj, loading: false });
    setOpenModalOutreach(true);
  };

  // const handleEmailOutreach = (linkId: number) => {
  //   setContactLinkId(linkId);
  //   setOpenModalOutreach(true);
  // };

  const deleteLinkAction = (id: number) => {
    setDeleteLink(id);
    setModalDelete(true);
  };

  const getAllCounts = async () => {
    setIsLoadingCount(true);
    try {
      const resp = await countLink(GlobalContext.site_selected, UserContext.id);
      setCountObject({
        percentage_remaining: resp.percentage_remaining,
        amount_remaining: resp.amount_remaining,
        user_percentage_allowed: resp.user_percentage_allowed,
        site_default_percentage: resp.site_default_percentage,
        previous_month_total: resp.previous_month_total,
        counter_links_dupulicated_current_month:
          resp.counter_links_dupulicated_current_month,
      });
    } catch (err) {
      setCountObject({
        percentage_remaining: 0,
        amount_remaining: 0,
        user_percentage_allowed: null,
        site_default_percentage: 0,
        previous_month_total: 0,
        counter_links_dupulicated_current_month: 0,
      });
    } finally {
      setIsLoadingCount(false);
    }
  };

  useEffect(() => {
    if (GlobalContext.site_selected > 0) getAllCounts();
  }, [GlobalContext.site_selected]);

  // useEffect(() => {
  //   if (!loadingCount) setTabsArr(tabsArrNumber(countAssignedToMe));
  // }, [countAssignedToMe, loadingCount]);

  const tooltipInformation = () => {
    const lastMonth = dayjs().subtract(1, 'M').format('MMMM');

    return (
      <>
        <Box>
          <Typography fontSize={14} fontWeight={500}>
            Outreach in {lastMonth}: {countObject.previous_month_total}
          </Typography>
          <Typography fontSize={14} fontWeight={500}>
            Cannibalization Attempts in {currentMonth}:{' '}
            {countObject.counter_links_dupulicated_current_month}
          </Typography>
          {countObject.user_percentage_allowed && (
            <Typography fontSize={14} fontWeight={500}>
              Cannibalization Allowed: {countObject.user_percentage_allowed} %
            </Typography>
          )}
          {!countObject.user_percentage_allowed && (
            <Typography fontSize={14} fontWeight={500}>
              Cannibalization Allowed (site default):{' '}
              {countObject.site_default_percentage} %
            </Typography>
          )}
          <Typography fontSize={14} fontWeight={500}>
            Attempts Remaining (+ Flat amount): {countObject.amount_remaining}
          </Typography>
        </Box>
      </>
    );
  };

  const informationBuildOutreach = () => {
    return (
      <>
        <Box>
          <Typography fontSize={14} fontWeight={500}>
            {currentMonth} Send Events: {resultsBuild.send_events}
          </Typography>
          <Typography fontSize={14} fontWeight={500}>
            {currentMonth} Open Events: {resultsBuild.open_events}
          </Typography>
        </Box>
      </>
    );
  };

  const changeSearchContacts = () => {
    // if (searchContacts)
    //   filtersLinks.updateFiltersChange({
    //     ...filtersLinks.filterValuesChange,
    //     search_url: '',
    //   });
    // else
    //   filtersLinks.updateFiltersChange({
    //     ...filtersLinks.filterValuesChange,
    //     search_email: '',
    //   });
    setSearchContacts(!searchContacts);
  };

  useEffect(() => {
    if (
      formSidebar.type === 'load' &&
      !isLoadingContext.loading &&
      render !== 0 &&
      tab === 0
    ) {
      refreshLinks();
    }
  }, [formSidebar]);

  const informationToolTip = () => {
    return (
      <>
        <Box>
          {IS_USER_ADMIN.includes(UserContext.profile.role) && (
            <Typography fontSize={13} fontWeight={450}>
              {currentMonth} Open Rate: {resultsBuild.open_rate}
            </Typography>
          )}
          <Typography fontSize={13} fontWeight={450}>
            Cannibalization Remaining: {countObject.percentage_remaining} %
          </Typography>
          <Typography fontSize={13} fontWeight={450}>
            Domain Exception Outreach Remaining: {countDomain}
          </Typography>
          <FlexBox>
            <Typography fontSize={13} fontWeight={450}>
              {currentMonth} Unsubscribed: {countUnsubscribe} (
              {previousMonthResult})
            </Typography>
            {countUnsubscribe > previousMonthResult && (
              <ArrowCircleUpIcon
                sx={{
                  color: 'red',
                  marginLeft: 0.5,
                  '&:hover': { color: 'purple' },
                }}
              />
            )}
            {countUnsubscribe < previousMonthResult && (
              <ArrowCircleDownIcon
                sx={{
                  color: 'greem',
                  marginLeft: 0.5,
                  '&:hover': { color: 'purple' },
                }}
              />
            )}
          </FlexBox>
        </Box>
      </>
    );
  };

  type Entry = {
    id: number;
    value: any;
  };

  type EntriesObject = Record<string, any>;

  const hasValidValue = (obj: EntriesObject): boolean => {
    return Object.values(obj).some(
      entry =>
        entry &&
        typeof entry === 'object' &&
        (typeof entry.value !== 'string' || entry.value.trim() !== '') &&
        entry.value !== null &&
        entry.value !== false,
    );
  };

  const findFilters = () => {
    if (tab === 0) {
      if (GlobalContext.links.search_filter !== '') return true;
      const copyObj = { ...filtersLinks.filterValuesDefault };
      delete copyObj.show_all;
      delete copyObj.search_url;
      delete copyObj.search_email;
      return hasValidValue(copyObj);
    }
    if (tab === 1) {
      if (GlobalContext.unclaimed.search_filter !== '') return true;

      const copyObj = { ...filterUnclaimed.filterValuesDefault };
      delete copyObj.show_all;
      delete copyObj.search_url;
      delete copyObj.show_unclaimed;
      delete copyObj.status;
      delete copyObj.search_email;
      return hasValidValue(copyObj);
    }

    if (tab === 2) {
      const copyObj = { ...filterClaims.filterValuesDefault };
      delete copyObj.show_hidden;
      return hasValidValue(copyObj);
    }

    if (tab === 3) {
      const copyObj = { ...filterMaster.filterValuesDefault };
      delete copyObj.show_hidden;
      delete copyObj.status;
      return hasValidValue(copyObj);
    }

    return false;
  };

  return (
    <>
      <RightColumns pl={0} pr={0}>
        {/* <TitleButton
          disabled={GlobalContext.site_selected === 0}
          title=""
          disabledButtons={isLoadingContext.loading}
          buttonText="Add Link"
          button={sendBackObject(UserContext.profile.role, ROLES_LINKS).add}
          buttonFunction={openAddLink}
          updateFormDrawer={updateFormDrawer}
        ></TitleButton> */}
        <FlexBoxBetween pr={1.2} pl={1.5}>
          <FlexBox>
            <IconButton
              aria-label="filter"
              disabled={isLoadingContext.loading}
              id="long-button"
              sx={{
                border: 1,
                borderRadius: '10%',
                bgcolor: findFilters() ? '#BAF372' : '',
                '&.Mui-disabled': {
                  bgcolor: findFilters() ? '#BAF372' : '',
                },
              }}
              aria-haspopup="true"
              onClick={() => {
                if (updateFormDrawer) updateFormDrawer();
              }}
            >
              <FilterListIcon
                sx={{
                  color: 'black',
                  '&:hover': { color: 'green' },
                }}
              />
            </IconButton>
            <Typography ml={1} fontSize={17} fontWeight="bold">
              Filters
            </Typography>
            {loadingBuild ||
            loadingCountDomain ||
            loadingCountUnsubscribe ||
            isLoadingCount ? (
              <Box sx={{ width: 100, ml: 3 }}>
                <Skeleton animation="wave" />
              </Box>
            ) : (
              <HtmlTooltip title={informationToolTip()}>
                <FlexBox ml={3} sx={{ cursor: 'pointer' }}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignContent="center"
                    border="1px solid black"
                    borderRadius={10}
                    p={1}
                    mr={0.5}
                  >
                    <EqualizerIcon
                      sx={{
                        color: 'black',
                        fontSize: 15,
                      }}
                    />
                  </Box>
                  <Typography fontSize={12.6} color="black" fontWeight="bold">
                    Monthly Stats
                  </Typography>
                </FlexBox>
              </HtmlTooltip>
            )}
          </FlexBox>
          <FlexBox>
            {claimsCount.count > 0 && tab === 2 && (
              <>
                <Chip
                  label={`Results Found: ${claimsCount.count ?? 0}`}
                  sx={{ fontWeight: 600, mx: 2 }}
                />
                <Chip
                  label={`Total Points: ${claimsCount.total_points_sum ?? 0}`}
                  sx={{ fontWeight: 600 }}
                />
              </>
            )}

            {IS_USER_ADMIN_AND_STRAT.includes(UserContext.profile.role) &&
              tab === 2 && (
                <MainButton
                  disabled={isLoadingContext.loading}
                  variant="contained"
                  endIcon={<DownloadIcon />}
                  type="submit"
                  sx={{
                    mx: 2,
                    backgroundColor: '#84D0F0',
                    color: 'black',
                    '&:hover': {
                      backgroundColor: '#84D0F0',
                    },
                  }}
                  onClick={downloadContentClaims}
                >
                  Export
                </MainButton>
              )}

            {masterCount.count > 0 && tab === 3 && (
              <>
                <Chip
                  label={`Results Found: ${masterCount.count ?? 0}`}
                  sx={{ fontWeight: 600, mx: 2 }}
                />
                <Chip
                  label={`Total Points: ${masterCount.total_points_sum ?? 0}`}
                  sx={{ fontWeight: 600 }}
                />
              </>
            )}

            {IS_USER_ADMIN.includes(UserContext.profile.role) && tab === 3 && (
              <MainButton
                disabled={isLoadingContext.loading}
                variant="contained"
                endIcon={<DownloadIcon />}
                type="submit"
                sx={{
                  backgroundColor: '#84D0F0',
                  color: 'black',
                  mx: 2,
                  '&:hover': {
                    backgroundColor: '#84D0F0',
                  },
                }}
                onClick={downloadContent}
              >
                Export
              </MainButton>
            )}

            {countLinksUnclaimed > 0 && tab === 1 && (
              <Chip
                label={`Results Found: ${countLinksUnclaimed ?? 0}`}
                sx={{ fontWeight: 600, mx: 2 }}
              />
            )}
            {count > 0 && tab === 0 && (
              <Chip
                label={`Results Found: ${count ?? 0}`}
                sx={{ fontWeight: 600, mx: 2 }}
              />
            )}
            {IS_USER_ADMIN_AND_STRAT.includes(UserContext.profile.role) &&
              tab === 0 && (
                <MainButton
                  disabled={isLoadingContext.loading}
                  variant="contained"
                  endIcon={<DownloadIcon />}
                  type="submit"
                  sx={{
                    mr: 2,
                    backgroundColor: '#84D0F0',
                    color: 'black',
                    '&:hover': {
                      backgroundColor: '#84D0F0',
                    },
                  }}
                  onClick={downloadContentLinks}
                >
                  Export
                </MainButton>
              )}
            {tab < 1 && (
              <MainButton
                disabled={isLoadingContext.loading}
                variant="contained"
                sx={{
                  color: 'black',
                  backgroundColor: '#BAF372',
                  '&:hover': {
                    backgroundColor: '#BAF372',
                  },
                }}
                type="submit"
                onClick={() => {
                  if (formSidebar.type === 'add') {
                    updateWarningCreate(true);
                  } else {
                    const obj = { ...LinkFormDefault };
                    obj.owner = UserContext.id;
                    obj.assignee = UserContext.id;
                    obj.site = GlobalContext.site_selected ?? 0;
                    replaceLinkForm(obj);
                    updateSidebar({
                      type: 'add',
                    });
                    resetPendingListObject();

                    updateAvailable({
                      disabled: false,
                      disabledSaveEmail: false,
                    });
                  }
                  // openAddLink();
                }}
              >
                Add Link
              </MainButton>
            )}
          </FlexBox>
        </FlexBoxBetween>
        <Box marginTop={2}>
          <FlexBoxBetween
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              pr: 1.2,
            }}
          >
            <Box>
              <TabParent
                valueTab={tab}
                setTab={(value: any) => {
                  if (value === 2) updateClaimId(0);

                  if (formSidebar.type === 'claim') {
                    updateSidebar({ type: 'read' });
                  }
                  if (formSidebar.type === 'add') {
                    updateModalWarningLink(true);
                    setValueTab(value);
                  } else if (
                    formSidebar.type === 'edit' &&
                    linkForm.id &&
                    linkForm.id !== 0
                  ) {
                    updateModalWarningLink(true);
                    setValueTab(value);

                    // replaceObjEditForm(
                    //   ObjEditFormLink,
                    //   LinkFormDefault,
                    //   IS_USER_ADMIN.includes(UserContext.profile.role),
                    // );
                  } else {
                    setTab(value);
                    replaceObjEditForm(
                      ObjEditFormLink,
                      LinkFormDefault,
                      IS_USER_ADMIN.includes(UserContext.profile.role),
                    );
                  }
                }}
                tabs={
                  IS_USER_ADMIN_MANAGER_STRAT.includes(UserContext.profile.role)
                    ? tabsArrAdmin
                    : tabsArrDefault
                }
                center={false}
              />
            </Box>
            {tab === 3 && (
              <FlexBox width="100%" justifyContent="end">
                <Box width="58%" mr={0.5}>
                  <SearchInput
                    placeholder="Search by URL"
                    id="search_url"
                    filter={false}
                    onKeyDown={() =>
                      filterMaster.updateFiltersDefault(
                        filterMaster.filterValuesChange,
                      )
                    }
                    onClear={() => {
                      filterMaster.updateFiltersChange({
                        ...filterMaster.filterValuesChange,
                        url: { id: 'url', value: '' },
                      });
                    }}
                    value={filterMaster.filterValuesChange.url.value}
                    onChangeNormal={query => {
                      filterMaster.updateFiltersChange({
                        ...filterMaster.filterValuesChange,
                        url: { id: 'url', value: query },
                      });
                    }}
                    disabled={isLoadingContext.loading}
                  />
                </Box>
                <NewPagination
                  countItems={masterCount.count}
                  page={GlobalContext.master.page}
                  pageSize={GlobalContext.master.page_size}
                  handleChangeRowsPerPage={(value: number) => {
                    setGlobalContext({
                      ...GlobalContext,
                      master: {
                        ...GlobalContext.master,
                        page: 1,
                        page_size: value,
                      },
                    });
                  }}
                  handleChangePage={(newPage: number) => {
                    setGlobalContext({
                      ...GlobalContext,
                      master: {
                        ...GlobalContext.master,
                        page: newPage,
                      },
                    });
                  }}
                />
              </FlexBox>
            )}
            {tab === 2 && (
              <FlexBox width="100%" justifyContent="end">
                <Box width="58%" mr={0.5}>
                  <SearchInput
                    placeholder="Search by URL"
                    id="search_url"
                    filter={false}
                    onClear={() => {
                      filterClaims.updateFiltersChange({
                        ...filterClaims.filterValuesChange,
                        url: { id: 'url', value: '' },
                      });
                    }}
                    onKeyDown={() =>
                      filterClaims.updateFiltersDefault(
                        filterClaims.filterValuesChange,
                      )
                    }
                    value={filterClaims.filterValuesChange.url.value}
                    onChangeNormal={query => {
                      filterClaims.updateFiltersChange({
                        ...filterClaims.filterValuesChange,
                        url: { id: 'url', value: query },
                      });
                    }}
                    disabled={isLoadingContext.loading}
                  />
                </Box>
                <NewPagination
                  countItems={claimsCount.count}
                  page={GlobalContext.claims.page}
                  pageSize={GlobalContext.claims.page_size}
                  handleChangeRowsPerPage={(value: number) => {
                    setGlobalContext({
                      ...GlobalContext,
                      claims: {
                        ...GlobalContext.claims,
                        page: 1,
                        page_size: value,
                      },
                    });
                  }}
                  handleChangePage={(newPage: number) => {
                    setGlobalContext({
                      ...GlobalContext,
                      claims: {
                        ...GlobalContext.claims,
                        page: newPage,
                      },
                    });
                  }}
                />
              </FlexBox>
            )}
            {tab === 1 && (
              <FlexBox width="100%" justifyContent="end">
                <Box width="58%" mr={0.5}>
                  <SearchInput
                    placeholder="Search by URL"
                    id="search_url"
                    onClear={() => {
                      filterUnclaimed.updateFiltersChange({
                        ...filterUnclaimed.filterValuesChange,
                        search_url: '',
                      });
                    }}
                    filter={false}
                    onKeyDown={() =>
                      filterUnclaimed.updateFiltersDefault(
                        filterUnclaimed.filterValuesChange,
                      )
                    }
                    value={filterUnclaimed.filterValuesChange.search_url}
                    onChangeNormal={query => {
                      filterUnclaimed.updateFiltersChange({
                        ...filterUnclaimed.filterValuesChange,
                        search_url: query,
                      });
                    }}
                    disabled={isLoadingContext.loading}
                  />
                </Box>
                <NewPagination
                  countItems={countLinksUnclaimed}
                  page={GlobalContext.unclaimed.page}
                  pageSize={GlobalContext.unclaimed.page_size}
                  handleChangeRowsPerPage={(value: number) => {
                    setGlobalContext({
                      ...GlobalContext,
                      unclaimed: {
                        ...GlobalContext.unclaimed,
                        page: 1,
                        page_size: value,
                      },
                    });
                  }}
                  handleChangePage={(newPage: number) => {
                    setGlobalContext({
                      ...GlobalContext,
                      unclaimed: {
                        ...GlobalContext.unclaimed,
                        page: newPage,
                      },
                    });
                  }}
                />
              </FlexBox>
            )}
            {/* {tab === 1 && (
              <FlexBox width="100%" justifyContent="end">
                <Box width="58%" mr={0.5}>
                  <SearchInput
                    placeholder="Search by URL"
                    id="search_url"
                    filter={false}
                    onKeyDown={() =>
                      filterAssigned.updateFiltersDefault(
                        filterAssigned.filterValuesChange,
                      )
                    }
                    value={filterAssigned.filterValuesChange.url.value}
                    onChangeNormal={query => {
                      filterAssigned.updateFiltersChange({
                        ...filterAssigned.filterValuesChange,
                        url: { id: 'url', value: query },
                      });
                    }}
                    disabled={isLoadingContext.loading}
                  />
                </Box>
                <NewPagination
                  countItems={linksUserCount}
                  page={GlobalContext.assigned_me.page}
                  pageSize={GlobalContext.assigned_me.page_size}
                  handleChangeRowsPerPage={(value: number) => {
                    setGlobalContext({
                      ...GlobalContext,
                      assigned_me: {
                        ...GlobalContext.assigned_me,
                        page: 1,
                        page_size: value,
                      },
                    });
                  }}
                  handleChangePage={(newPage: number) => {
                    setGlobalContext({
                      ...GlobalContext,
                      assigned_me: {
                        ...GlobalContext.assigned_me,
                        page: newPage,
                      },
                    });
                  }}
                />
              </FlexBox>
            )} */}
            {tab === 0 && (
              <FlexBox width="70%" justifyContent="end">
                <Box width="48%" mr={0.5}>
                  <SearchInput
                    placeholder={
                      searchContacts
                        ? 'Search by Contact Email'
                        : 'Search by URL'
                    }
                    id="search_url"
                    dropdown={{
                      value: searchContacts ? 'contacts' : 'url',
                      onChange: (value: string) => {
                        if (value === 'url') setSearchContacts(false);
                        else setSearchContacts(true);
                      },
                    }}
                    filter={false}
                    onKeyDown={() => {
                      filtersLinks.updateFiltersDefault(
                        filtersLinks.filterValuesChange,
                      );
                    }}
                    onClear={() => {
                      if (searchContacts)
                        filtersLinks.updateFiltersChange({
                          ...filtersLinks.filterValuesChange,
                          search_email: '',
                        });
                      else
                        filtersLinks.updateFiltersChange({
                          ...filtersLinks.filterValuesChange,
                          search_url: '',
                        });
                    }}
                    value={
                      searchContacts
                        ? filtersLinks.filterValuesChange.search_email
                        : filtersLinks.filterValuesChange.search_url
                    }
                    onChangeNormal={query => {
                      if (searchContacts)
                        filtersLinks.updateFiltersChange({
                          ...filtersLinks.filterValuesChange,
                          search_email: query,
                        });
                      else
                        filtersLinks.updateFiltersChange({
                          ...filtersLinks.filterValuesChange,
                          search_url: query,
                        });
                    }}
                    disabled={isLoadingContext.loading}
                  />
                </Box>
                <NewPagination
                  countItems={count}
                  page={GlobalContext.links.page}
                  pageSize={GlobalContext.links.page_size}
                  handleChangeRowsPerPage={(value: number) => {
                    setGlobalContext({
                      ...GlobalContext,
                      links: {
                        ...GlobalContext.links,
                        page: 1,
                        page_size: value,
                      },
                    });
                  }}
                  handleChangePage={(newPage: number) => {
                    setGlobalContext({
                      ...GlobalContext,
                      links: { ...GlobalContext.links, page: newPage },
                    });
                  }}
                />
              </FlexBox>
            )}
          </FlexBoxBetween>
          <TabPanel value={tab} index={0}>
            <EnhancedTable
              fetchLinks={refreshLinks}
              tabLinks={tab}
              contacts={contacts}
              statusOfLinks={statusOfLinks}
              handleSendEmail={handleSendEmail}
              columnsVisibility={columnsVisibility}
              editLink={editLink}
              links={links}
              error={error}
              loading={loading}
              deleteLinkAction={deleteLinkAction}
              countItems={count}
            />
          </TabPanel>
          {/* <TabPanel value={tab} index={1}>
            <ProspectingEligibleTable
              error={isError}
              notesLinks={notesLinksUser}
              count={linksUserCount}
              handleMessage={showMessage}
              loading={isLoadingUserLinks}
              fetchInfo={getUserLinks}
              prospects={linksUser}
            />
          </TabPanel> */}
          <TabPanel value={tab} index={1}>
            <UnclaimedTab
              fetchLinks={refreshLinksUnclaimed}
              handleSendEmail={handleSendEmail}
              columnsVisibility={columnsVisibility}
              editLink={editLink}
              links={linksUnclaimed}
              error={errorUnclaimed}
              loading={loadingUnclaimed}
              deleteLinkAction={deleteLinkAction}
              countItems={countLinksUnclaimed}
            />
          </TabPanel>
          <TabPanel value={tab} index={2}>
            <Claims
              fetchInfo={fetchClaims}
              loading={isLoadingClaims}
              error={isErrorClaims}
              claimsArr={claimsLinks}
              handleMessage={showMessage}
              tab={tab}
              count={claimsCount.count}
            />
          </TabPanel>
          <TabPanel value={tab} index={3}>
            <MasterLinks
              fetchInfo={fetchMaster}
              handleMessage={showMessage}
              loading={isLoadingMaster}
              error={isErrorMaster}
              claimsArr={masterLinks}
              count={masterCount.count}
              tab={tab}
            />
          </TabPanel>
        </Box>
        <MainDrawer openDrawer={openDrawer} setOpenDrawer={setOpenDrawer}>
          {drawerOption === 1 && (
            <AddLink
              setOpenDrawer={setOpenDrawer}
              type="add"
              fetchLinks={refreshLinks}
              showMessage={showMessage}
              openModalNewContact={objectEditLink.openNewContact}
              tempsLinks={objectEditLink.temp}
              siteTemp={objectEditLink.siteTemp}
              audienceTemporal={objectEditLink.aud}
              affinityTemporal={objectEditLink.aff}
              modified=""
            />
          )}
          {drawerOption === 2 && (
            <AddLink
              setOpenDrawer={setOpenDrawer}
              showMessage={showMessage}
              type="edit"
              fetchLinks={refreshLinks}
              openModalNewContact={objectEditLink.openNewContact}
              siteTemp={objectEditLink.siteTemp}
              audienceTemporal={objectEditLink.aud}
              affinityTemporal={objectEditLink.aff}
              objEdit={objectEditLink.obj}
              modified={objectEditLink.modified}
              tempsLinks={objectEditLink.temp}
              tab={objectEditLink.tab}
              is_unsubscribed={objectEditLink.is_unsubscribed}
              unsubscribed_date={objectEditLink.unsubscribed_date}
              is_older_than_ninety_days={
                objectEditLink.is_older_than_ninety_days
              }
              is_older_than_thirty_days={
                objectEditLink.is_older_than_thirty_days
              }
            />
          )}
        </MainDrawer>
        <FeedbackMessage
          open={feedbackOpen}
          setOpen={setFeedbackOpen}
          vertical="top"
          horizontal="center"
          severity={feedbackMessage.severity}
          message={feedbackMessage.message}
        />
        {/* <ConfirmOutreach
        open={openModalOutreach}
        closeModal={setOpenModalOutreach}
        linkId={contactLinkId}
      /> */}
      </RightColumns>
      <ConfirmEmail
        open={openModalOutreach}
        closeModal={setOpenModalOutreach}
        contacts={sendEmailObject.obj.contacts}
        handleOk={sendNewEmail}
        loading={sendEmailObject.loading}
      />
      <DeleteModalLinks
        open={modalDelete}
        closeModal={setModalDelete}
        id={deleteLink}
        showMessage={showMessage}
        refreshLinks={refreshLinks}
      />
      <AddWarningLink
        open={modalWarningLink}
        closeModal={updateModalWarningLink}
        continueModal={() => {
          setTab(valueTab);
          updateModalWarningLink(false);
          updateSidebar({ type: 'read' });

          const obj = { ...LinkFormDefault };

          replaceObjEditForm(
            ObjEditFormLink,
            obj,
            IS_USER_ADMIN.includes(UserContext.profile.role),
          );
          setTab(valueTab);
          updateAvailable({ disabled: true, disabledSaveEmail: true });
        }}
      />

      <AddWarningLink
        open={warningEditLink}
        closeModal={updateModalWarningEditLink}
        body="If you continue, your progress while editing this link will be deleted"
        continueModal={() => {
          updateModalWarningEditLink(false);

          updateAvailable({ disabled: true, disabledSaveEmail: true });

          if (linkToEdit.link)
            followUpEdit(linkToEdit.link, linkToEdit.statusLink);
        }}
      />

      <AddWarningLink
        open={warningCreate}
        closeModal={updateWarningCreate}
        continueModal={() => {
          updateWarningCreate(false);
          const obj = { ...LinkFormDefault };
          obj.owner = UserContext.id;
          obj.assignee = UserContext.id;
          obj.site = GlobalContext.site_selected ?? 0;
          replaceLinkForm(obj);
          updateSidebar({
            type: 'add',
          });

          updateAvailable({ disabled: false, disabledSaveEmail: false });
        }}
      />
    </>
  );
};

export default Links;
