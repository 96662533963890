/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useContext, useRef } from 'react';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import DateFilters from '../../../components/Filters/DateFilters';
import AutoCompleteSelect from '../../../components/Filters/AutoCompleteSelect';
import { Owners, UserContextType } from '../../../models/User';
import { FlexBox, FlexBoxBetween } from '../../../components/tableItems';
import { MainButton } from '../../../components/buttons';
import useLogStore from '../../../stores/history/log';
import { BasicSelect } from '../../../components/Forms/FormInputs';
import { EVENT_TYPE_OPTIONS, filtersLogsDefault } from '../../../models/Logs';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '../../../components/Accordion/Accordion';
import Context from '../../../context/UserContext';
import { IS_USER_ADMIN } from '../../../constants/user';

interface Props {
  owners: Owners[];
  updateFormDrawer: () => void;
}

const FiltersLogs = ({ owners, updateFormDrawer }: Props) => {
  const {
    filtersValuesChange,
    filtersValuesDefault,
    updateFiltersChange,
    updateFiltersDefault,
    updateLogStore,
    logObject,
    loading,
  } = useLogStore();
  const { UserContext } = useContext(Context) as UserContextType;
  const isFirstRender = useRef(true);

  const [filtersKey, setFiltersKey] = useState(0);

  const closeAll = () => setFiltersKey(prevKey => prevKey + 1);

  const getQueryFinal = (search_url: string, search_email: string) => {
    let queryFinal = '';
    if (search_url !== '') {
      queryFinal += `&search_url=${search_url}`;
    }
    if (search_email !== '') {
      queryFinal += `&search_email=${search_email}`;
    }
    return queryFinal;
  };

  const createQuery = () => {
    // const copyObj = { ...filtersValuesDefault };
    // const queryFinal = getQueryFinal(
    //   copyObj.search_url ?? '',
    //   copyObj.search_email ?? '',
    // );
    // delete copyObj.search_email;
    // delete copyObj.search_url;
    const copy = { ...filtersValuesDefault };
    if (!IS_USER_ADMIN.includes(UserContext.profile.role)) {
      copy.owner = { id: 'owner', value: `${UserContext.id}` };
    }
    const copyFilters = JSON.parse(JSON.stringify(copy));

    const ArrFilters: { id: string; value: string }[] = Object.assign(
      [],
      Object.values(copyFilters),
    );
    const arrayElements = ArrFilters.filter(item => item.value);
    const queryString = arrayElements.map(
      query => `&${query.id}=${query.value}`,
    );

    updateLogStore({ page: 1, filters: queryString.join('') });
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      const copyFiltersLogs = { ...filtersLogsDefault };
      copyFiltersLogs.owner = { id: 'owner', value: `${UserContext.id}` };
      updateFiltersChange(copyFiltersLogs);
      updateFiltersDefault(copyFiltersLogs);
      return;
    }
    createQuery();
  }, [filtersValuesDefault]);

  useEffect(() => {
    return () => {
      updateFiltersChange(filtersLogsDefault);
      updateFiltersDefault(filtersLogsDefault);
    };
  }, []);

  const onEnterOption = () => updateFiltersDefault(filtersValuesDefault);

  const filtersArray = [
    {
      id: 1,
      title: 'Owner',
      admin: true,
      countUse:
        filtersValuesChange.owner.value !== ''
          ? filtersValuesChange.owner.value.split(',').length
          : 0,
      body: (
        <AutoCompleteSelect
          disabledInput={loading}
          options={owners.map(item => {
            return { id: item.id, name: item.username };
          })}
          clearAll={false}
          onEnter={onEnterOption}
          placeholder="Owner"
          updateFiltersValue={(id: string, value: string) => {
            updateFiltersChange({ owner: { id, value } });
          }}
          value={owners
            .map(item => {
              return { id: item.id, name: item.username };
            })
            .filter(item => {
              const id = item.id as number;
              return (
                filtersValuesChange.owner.value
                  .split(',')
                  .indexOf(id.toString()) !== -1
              );
            })}
          id={filtersValuesChange.owner.id}
        />
      ),
      disabled: !(owners.length > 0),
    },
    {
      id: 2,
      title: 'Created Date',
      countUse:
        filtersValuesChange.created_before.value &&
        filtersValuesChange.created_after.value
          ? 2
          : filtersValuesChange.created_after.value ||
            filtersValuesChange.created_before.value
          ? 1
          : 0,
      body: (
        <DateFilters
          onEnter={onEnterOption}
          disableInput={false}
          min_id={filtersValuesChange.created_after.id}
          min_value={filtersValuesChange.created_after.value}
          max_id={filtersValuesChange.created_before.id}
          max_value={filtersValuesChange.created_before.value}
          updateDates={(id: string, value: string | null) => {
            if (id === filtersValuesChange.created_after.id) {
              updateFiltersChange({ created_after: { id, value } });
              return;
            }
            if (id === filtersValuesChange.created_before.id) {
              updateFiltersChange({ created_before: { id, value } });
            }
          }}
        />
      ),
      disabled: false,
    },

    {
      id: 3,
      title: 'Event Type',
      countUse:
        filtersValuesChange.event_type.value !== ''
          ? filtersValuesChange.event_type.value.split(',').length
          : 0,
      body: (
        <BasicSelect
          required
          options={EVENT_TYPE_OPTIONS}
          nameInput="event_type"
          filter
          disabled={false}
          value={filtersValuesChange.event_type.value}
          onChange={(value: string) =>
            updateFiltersChange({ event_type: { id: 'event_type', value } })
          }
        />
      ),
      disabled: false,
    },
  ];

  return (
    <Box marginTop={2}>
      <FlexBoxBetween px={3}>
        <Typography sx={{ fontWeight: 900, fontSize: 16 }}>Filters</Typography>
        <IconButton
          aria-label="close"
          disabled={false}
          id="long-button"
          aria-haspopup="true"
          onClick={() => updateFormDrawer()}
        >
          <CloseIcon
            sx={{
              color: 'black',
              '&:hover': { color: 'red' },
            }}
          />
        </IconButton>
      </FlexBoxBetween>
      <FlexBoxBetween px={3} mt={1}>
        <MainButton
          disabled={loading}
          size="small"
          variant="contained"
          type="submit"
          onClick={() => {
            updateFiltersDefault(filtersValuesChange);
            updateFormDrawer();
          }}
        >
          Apply Filters
        </MainButton>
        <Typography
          onClick={() => {
            if (loading) return;
            updateFiltersChange(filtersLogsDefault);
            updateFiltersDefault(filtersLogsDefault);
            updateFormDrawer();
            closeAll();
          }}
          sx={{
            fontSize: 14,
            cursor: 'pointer',
            ':hover': {
              color: 'rgba(0, 0, 0, 0.8)',
            },
          }}
        >
          Clear All
        </Typography>
      </FlexBoxBetween>
      <Box marginTop={1.5}>
        <Divider sx={{ border: '1px solid rgba(0, 0, 0, 0.06)' }} />

        <div key={filtersKey}>
          {filtersArray.map(filter => (
            <Box
              position="relative"
              key={filter.id}
              display={
                filter.admin &&
                !IS_USER_ADMIN.includes(UserContext.profile.role)
                  ? 'none'
                  : ''
              }
            >
              <Accordion>
                <AccordionSummary
                  disabled={filter.disabled}
                  aria-controls={`${filter.title}-content`}
                  id={`${filter.title}-header`}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    <FlexBox>
                      <Box
                        sx={{
                          backgroundColor:
                            filter.countUse !== undefined && filter.countUse > 0
                              ? '#CEFFBA'
                              : '',
                        }}
                      >
                        <Typography
                          textTransform="capitalize"
                          fontSize={12}
                          color="#000000"
                          fontWeight={700}
                        >
                          {filter.title}
                        </Typography>
                      </Box>
                      {filter.countUse !== undefined && filter.countUse > 0 && (
                        <Typography
                          textTransform="capitalize"
                          fontSize={12}
                          color="#000000"
                          marginLeft={1.5}
                          fontWeight={400}
                          sx={{
                            backgroundColor: '#E2E2E2',
                            padding: '2px 4px',
                            borderRadius: '2px',
                          }}
                        >
                          {filter.countUse}
                        </Typography>
                      )}
                    </FlexBox>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>{filter.body}</AccordionDetails>
              </Accordion>
            </Box>
          ))}
        </div>
      </Box>
    </Box>
  );
};

export default FiltersLogs;
