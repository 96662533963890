import { FiltersDates, FiltersItems } from './Filters';
import { HeadCell } from './Table';

export interface LogsOwnerI {
  id: number;
  username: string;
}

export interface LogsContactI {
  id: number;
  name: string;
  email: string;
  form_text: string;
  form_url: string;
  validation_status: string;
  status: string;
}

export interface LogsLinkI {
  id: number;
  url: string;
  modified: string;
  status: string;
}

export interface LogsConversationMessageI {
  id: number;
  scheduled_date: string;
  send_status: string;
}

export interface LogsObjectI {
  id: number;
  owner: LogsOwnerI;
  status_update_note: null | string;
  created: string;
  scheduled_date: string;
  event_type: string;
  note: string;
  link: LogsLinkI;
  conversation_message: null | LogsConversationMessageI;
  contact: LogsContactI;
}

export interface LogsResponseI {
  count: number;
  next: null | string;
  previous: null | string;
  results: LogsObjectI[];
}

export interface LogsRequestI {
  ordering: string;
  filters: string;
  page: number;
  page_size: number;
}

export const defaultLogs: LogsRequestI = {
  page: 1,
  page_size: 10,
  ordering: '-created',
  filters: '',
};

export interface FiltersLogsInterface {
  owner: FiltersItems;
  created_before: FiltersDates;
  created_after: FiltersDates;
  event_type: FiltersItems;
}

export const filtersLogsDefault: FiltersLogsInterface = {
  owner: { id: 'owner', value: '' },
  created_before: { id: 'created_before', value: null },
  created_after: { id: 'created_after', value: null },
  event_type: { id: 'event_type', value: '' },
};

export const EVENT_TYPE_MAP: Record<string, string> = {
  link_claim_created: 'Link Claim Created',
  link_claim_approved: 'Link Claim Approved',
  link_claim_rejected: 'Link Claim Rejected',
  link_created: 'Link Created',
  link_contact_added: 'Contact Added',
  link_status_modified: 'Link Status Change',
  link_contact_unsubscribed: 'Contact Unsubscribed',
  email_queued: 'Email Queued',
  email_scheduled: 'Email Scheduled',
  email_rescheduled: 'Email Rescheduled',
  email_sent: 'Email Sent',
  open_event: 'Email Opened',
  email_failed: 'Email Failed',
  email_draft_saved: 'Email Draft Saved',
};

export const EVENT_TYPE_OPTIONS = Object.entries(EVENT_TYPE_MAP).map(
  ([value, label]) => ({
    value,
    label,
  }),
);

export const getEventDisplayName = (eventKey: string): string =>
  EVENT_TYPE_MAP[eventKey] || 'Unknown Event';

export const logsHeadCellAdmin: HeadCell[] = [
  {
    id: 'url',
    disablePadding: true,
    disableSort: true,
    main: true,
    label: 'URL',
  },
  {
    id: 'created',
    disablePadding: false,
    disableSort: false,
    label: 'Event Date',
  },
  {
    id: 'created_time',
    disablePadding: false,
    disableSort: true,
    label: 'Event Time',
  },
  {
    id: 'event_type',
    disablePadding: false,
    disableSort: false,
    label: 'Event Type',
  },
  {
    id: 'contact',
    disablePadding: false,
    disableSort: true,
    label: 'Contact',
  },
  {
    id: 'status_update',
    disablePadding: false,
    disableSort: true,
    label: 'Status Update',
  },
  {
    id: 'owner',
    disablePadding: false,
    disableSort: true,
    label: 'Owner',
  },
];

export const logsHeadCell: HeadCell[] = [
  {
    id: 'url',
    disablePadding: true,
    disableSort: true,
    main: true,
    label: 'URL',
  },
  {
    id: 'created',
    disablePadding: false,
    disableSort: false,
    label: 'Event Date',
  },
  {
    id: 'created_time',
    disablePadding: false,
    disableSort: true,
    label: 'Event Time',
  },
  {
    id: 'event_type',
    disablePadding: false,
    disableSort: false,
    label: 'Event Type',
  },
  {
    id: 'contact',
    disablePadding: false,
    disableSort: true,
    label: 'Contact',
  },
  {
    id: 'status_update',
    disablePadding: false,
    disableSort: true,
    label: 'Status Update',
  },
];
