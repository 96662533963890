/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import { Box, Step, StepLabel, Stepper } from '@mui/material';
import React from 'react';
import { FlexBox, FlexBoxBetween } from '../../../../components/tableItems';
import { FormButtons, MainButton } from '../../../../components/buttons';
import useLinkForm from '../../../../stores/links/useLinkForm';

const steps = [
  {
    label: 'Link',
  },
  { label: 'Targeting' },
  { label: 'Claim' },
  { label: 'Points' },
];

interface ClaimAddHeaderProps {
  activeStep: number;
  saveClaim: () => void;
  updateStep: (step: number) => void;
  loadingForm: boolean;
  stepOneFormValid: boolean;
  stepTwoFormValid: boolean;
  stepThreeFormValid: boolean;
  updateOpenWarningAdd: (value: boolean) => void;
  validateClear: boolean;
}

const ClaimAddHeader: React.FC<ClaimAddHeaderProps> = ({
  activeStep,
  saveClaim,
  updateStep,
  loadingForm,
  stepOneFormValid,
  stepTwoFormValid,
  stepThreeFormValid,
  updateOpenWarningAdd,
  validateClear,
}) => {
  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      saveClaim();
      return;
    }

    updateStep(activeStep + 1);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((step, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: { optional?: React.ReactNode } = {};

          // if (isStepOptional(index)) {
          //   labelProps.optional = (
          //     <Typography variant="caption" fontSize={11}>
          //       Optional
          //     </Typography>
          //   );
          // }

          return (
            <Step key={index} {...stepProps}>
              <StepLabel
                {...labelProps}
                sx={{
                  '.MuiStepLabel-label': {
                    fontSize: 12,
                  },
                }}
              >
                {step.label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Box mt={2} mb={2}>
        <FlexBoxBetween>
          <FlexBox>
            <FormButtons
              loading={false}
              variant="contained"
              sx={{
                marginX: 1,
                fontSize: 11,

                backgroundColor: '#FFFFFF',
                color: 'black',
                borderColor: 'grey',
                border: 1,
                '&:hover': {
                  backgroundColor: '#FFFFFF',
                },

                '@media (min-width: 1900px)': {
                  fontSize: 14,
                },
              }}
              disabled={activeStep === 0 || loadingForm}
              onClick={() => updateStep(activeStep - 1)}
            >
              Back
            </FormButtons>
            <MainButton
              onClick={() => updateOpenWarningAdd(true)}
              disabled={false}
              variant="contained"
              sx={{
                marginRight: '',

                fontSize: 11,
                backgroundColor: '#FFFFFF',
                color: 'black',
                border: '1px solid grey',
                '&:hover': {
                  backgroundColor: '#FFFFFF',
                },

                '@media (min-width: 1900px)': {
                  fontSize: 14,
                },
              }}
            >
              Cancel
            </MainButton>
          </FlexBox>
          <FlexBox>
            <FormButtons
              loading={loadingForm}
              variant="contained"
              sx={{
                marginX: 1,
                background: '#BAF372',
                fontSize: 11,
                color: 'black',
                '&:hover': {
                  backgroundColor: '#BAF372',
                },

                '@media (min-width: 1900px)': {
                  fontSize: 14,
                },
              }}
              disabled={
                activeStep === 0
                  ? stepOneFormValid
                  : activeStep === 1
                  ? stepTwoFormValid
                  : activeStep === 2
                  ? stepThreeFormValid
                  : false
              }
              onClick={handleNext}
            >
              {activeStep === steps.length - 1 ? 'Save' : 'Next'}
            </FormButtons>
          </FlexBox>
        </FlexBoxBetween>
      </Box>
    </Box>
  );
};

export default ClaimAddHeader;
