import routesNames from '../routes/customRoutes';

export const ROLES_USER_VALUES = [
  'view only',
  'data analyst',
  'linkbuilder',
  'manager',
  'account manager',
  'contractor',
  'trainer',
  'strategist',
  'administrator',
  'dev admin',
];

/* eslint-disable import/prefer-default-export */
export const ROLES_USER = [
  { value: 'view only', label: 'View Only' },
  { value: 'data analyst', label: 'Data Analyst' },
  { value: 'linkbuilder', label: 'Linkbuilder' },
  { value: 'manager', label: 'Manager' },
  { value: 'account manager', label: 'Account Manager' },
  { value: 'contractor', label: 'Contractor' },
  { value: 'trainer', label: 'Trainer' },
  { value: 'strategist', label: 'Strategist' },
  { value: 'administrator', label: 'Administrator' },
  { value: 'dev admin', label: 'Dev Admin' },
];

export const ROLES_USER_MANAGERS = [
  { value: 'view only', label: 'View Only' },
  { value: 'linkbuilder', label: 'Linkbuilder' },
  { value: 'contractor', label: 'Contractor' },
];
export const ROLES_USER_ACCOUNT_MANAGER = [
  { value: 'view only', label: 'View Only' },
  { value: 'manager', label: 'Manager' },
  { value: 'linkbuilder', label: 'Linkbuilder' },
  { value: 'contractor', label: 'Contractor' },
];

export const USERS_WITH_SETTINGS_ACCESS = [
  'manager',
  'account manager',
  'strategist',
  'admin',
  'dev admin',
  'administrator',
];

export const IS_USER_ADMIN = ['admin', 'dev admin', 'administrator'];
export const IS_USER_ADMIN_AND_STRAT = [
  'admin',
  'dev admin',
  'administrator',
  'strategist',
];
export const IS_USER_ADMIN_MANAGER = [
  'admin',
  'dev admin',
  'administrator',
  'manager',
  'account manager',
];

export const IS_USER_ADMIN_MANAGER_STRAT = [
  'admin',
  'dev admin',
  'administrator',
  'manager',
  'account manager',
  'strategist',
];

export const ROLES_USER_PERMIT = {
  'view only': [routesNames.templates],
  'data analyst': [routesNames.home],
  linkbuilder: [
    routesNames.home,
    routesNames.templates,
    routesNames.prospecting,
    routesNames.history,
  ],
  manager: [
    routesNames.home,
    routesNames.templates,
    routesNames.prospecting,
    routesNames.settings,
    routesNames.users,
    routesNames.projectSites,
    routesNames.audienceaff,
    routesNames.history,
  ],
  'account manager': [
    routesNames.home,
    routesNames.templates,
    routesNames.prospecting,
    routesNames.settings,
    routesNames.users,
    routesNames.projectSites,
    routesNames.audienceaff,
    routesNames.history,
  ],
  contractor: [
    routesNames.home,
    routesNames.templates,
    routesNames.prospecting,
    routesNames.history,
  ],
  trainer: [
    routesNames.home,
    routesNames.templates,
    routesNames.prospecting,
    routesNames.history,
  ],
  strategist: [
    routesNames.home,
    routesNames.prospecting,
    routesNames.templates,
    routesNames.settings,
    routesNames.users,
    routesNames.projectSites,
    routesNames.audienceaff,
    routesNames.history,
  ],
  administrator: [
    routesNames.home,
    routesNames.templates,
    routesNames.prospecting,
    routesNames.settings,
    routesNames.users,
    routesNames.projectSites,
    routesNames.audienceaff,
    routesNames.history,
  ],
  'dev admin': [
    routesNames.home,
    routesNames.templates,
    routesNames.prospecting,
    routesNames.settings,
    routesNames.users,
    routesNames.projectSites,
    routesNames.audienceaff,
    routesNames.history,
  ],
};

export const ROLES_TEMPLATES = {
  'view only': { add: false, edit: false, delete: false, approve: false },
  linkbuilder: { add: true, edit: false, delete: false, approve: false },
  contractor: { add: true, edit: false, delete: false, approve: false },
  manager: { add: true, edit: true, delete: false, approve: false },
  'account manager': { add: true, edit: false, delete: false, approve: false },
  strategist: { add: true, edit: true, delete: true, approve: true },
  administrator: { add: true, edit: true, delete: true, approve: true },
  'dev admin': { add: true, edit: true, delete: true, approve: true },
};

export const ROLES_LINKS = {
  'data analyst': { add: false, edit: false, delete: false, assignee: false },
  linkbuilder: { add: true, edit: true, delete: false, assignee: false },
  trainer: { add: false, edit: false, delete: false, approve: false },
  contractor: { add: true, edit: true, delete: false, assignee: false },
  manager: { add: true, edit: true, delete: false, assignee: true },
  'account manager': { add: true, edit: true, delete: false, assignee: true },
  strategist: { add: true, edit: true, delete: false, assignee: true },
  administrator: { add: true, edit: true, delete: true, assignee: true },
  'dev admin': { add: true, edit: true, delete: true, assignee: true },
};

export const ROLES_USER_PAGE = {
  manager: { add: true, edit: true, delete: false },
  'account manager': { add: true, edit: true, delete: false },
  strategist: { add: false, edit: true, delete: false },
  administrator: { add: true, edit: true, delete: true },
  'dev admin': { add: true, edit: true, delete: true },
};

export const ROLES_CLIENTS = {
  manager: { add: false, edit: true, delete: false },
  'account manager': { add: false, edit: false, delete: false },
  strategist: { add: true, edit: true, delete: true },
  administrator: { add: true, edit: true, delete: true },
  'dev admin': { add: true, edit: true, delete: true },
};

export const ROLES_CATEGORIES = {
  manager: { add: false, edit: true, delete: false },
  'account manager': { add: false, edit: false, delete: false },
  strategist: { add: true, edit: true, delete: true },
  administrator: { add: true, edit: true, delete: true },
  'dev admin': { add: true, edit: true, delete: true },
};

export const ROLES_PROJECTS = {
  manager: { add: false, edit: true, delete: false },
  'account manager': { add: false, edit: false, delete: false },
  strategist: { add: true, edit: true, delete: true },
  administrator: { add: true, edit: true, delete: true },
  'dev admin': { add: true, edit: true, delete: true },
};

export const ROLES_SITES = {
  manager: { add: false, edit: true, delete: false },
  'account manager': { add: false, edit: false, delete: false },
  strategist: { add: true, edit: true, delete: true },
  administrator: { add: true, edit: true, delete: true },
  'dev admin': { add: true, edit: true, delete: true },
};

export const ROLES_AUD_AFF = {
  manager: { add: false, edit: false, delete: true },
  'account manager': { add: false, edit: false, delete: false },
  strategist: { add: true, edit: true, delete: true },
  administrator: { add: true, edit: true, delete: true },
  'dev admin': { add: true, edit: true, delete: true },
};

export const ACCOUNTS_USER = [
  { value: 'launch that', label: 'Launch That' },
  { value: 'dash bpo', label: 'Dash BPO' },
  { value: 'contractor', label: 'Contractor' },
];

export const ENVIRONMENTS = ['training', 'dev', 'stg', ''];
