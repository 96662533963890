import React, { useContext, useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';

import DrawerLinks from '../DefaultView/DrawerLinks';
import LogsTable from './Logs/LogsTable';
import RightColumns from '../../components/Columns/RightColumns';
import { FlexBox, FlexBoxBetween } from '../../components/tableItems';
import TabParent from '../../components/Tab/TabParent';
import TabPanel from '../../components/Tab/TabPanel';
import useOwners from '../../customHooks/useOwners';
import FiltersLogs from './Logs/FiltersLogs';
import useLogStore from '../../stores/history/log';
import { IS_USER_ADMIN } from '../../constants/user';
import { UserContextType } from '../../models/User';
import Context from '../../context/UserContext';

const tabsArr = [{ id: 0, label: 'Activity Log' }];

const History = () => {
  const { loading: loadingLogs, filtersValuesDefault } = useLogStore();
  const { UserContext } = useContext(Context) as UserContextType;

  const [drawerForm, setDrawerForm] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const { owners } = useOwners({ withFields: true });

  const updateFormDrawer = () => setDrawerForm(!drawerForm);

  type EntriesObject = Record<string, any>;

  const hasValidValue = (obj: EntriesObject): boolean => {
    const copyObj = { ...obj };
    if (!IS_USER_ADMIN.includes(UserContext.profile.role)) delete copyObj.owner;

    return Object.values(copyObj).some(
      entry =>
        entry &&
        typeof entry === 'object' &&
        (typeof entry.value !== 'string' || entry.value.trim() !== '') &&
        entry.value !== null &&
        entry.value !== false,
    );
  };

  return (
    <div>
      <DrawerLinks openDrawer={drawerForm} openCloseDrawer={updateFormDrawer}>
        <FiltersLogs owners={owners} updateFormDrawer={updateFormDrawer} />
      </DrawerLinks>
      {/* <div className="left_columns">
      
      </div> */}
      <div className="right_columns">
        <RightColumns pr={0} pl={0}>
          <FlexBoxBetween px={2}>
            <FlexBox>
              <IconButton
                aria-label="filter"
                id="long-button"
                sx={{
                  border: 1,
                  borderRadius: '10%',
                  bgcolor: hasValidValue(filtersValuesDefault) ? '#BAF372' : '',
                  '&.Mui-disabled': {
                    bgcolor: hasValidValue(filtersValuesDefault)
                      ? '#BAF372'
                      : '',
                  },
                }}
                aria-haspopup="true"
                onClick={updateFormDrawer}
                disabled={loadingLogs}
              >
                <FilterListIcon
                  sx={{
                    color: 'black',
                    '&:hover': { color: 'green' },
                  }}
                />
              </IconButton>
              <Typography ml={1} fontSize={17} fontWeight="bold">
                Filters
              </Typography>
            </FlexBox>
            <Typography fontSize={24} fontWeight={900}>
              History
            </Typography>
            <div />
          </FlexBoxBetween>

          <Box mt={2}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                marginTop: 3,
              }}
            >
              <TabParent
                valueTab={activeTab}
                setTab={setActiveTab}
                tabs={tabsArr}
                center={false}
              />
            </Box>
          </Box>
          <TabPanel value={activeTab} index={0}>
            <LogsTable />
          </TabPanel>
        </RightColumns>
      </div>
    </div>
  );
};

export default History;
