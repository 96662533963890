/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import React, { useContext, useEffect, useState } from 'react';
import { Divider, Typography } from '@mui/material';
import {
  createClaimLink,
  editClaimLink,
  getClaimInfo,
  getLinksClaims,
} from '../../../../helpers/claims';
import useLinkForm from '../../../../stores/links/useLinkForm';
import {
  claimPostObjDefault,
  ClaimPostPutInt,
  ClaimsInt,
  claimsObjDef,
} from '../../../../models/Links';
import { FlexBox, FlexBoxBetween } from '../../../../components/tableItems';
import { FormButtons, MainButton } from '../../../../components/buttons';
import ClaimForm from './ClaimForm';
import useOwners from '../../../../customHooks/useOwners';
import { Owners, UserContextType } from '../../../../models/User';
import { BasicArray, SeverityType } from '../../../../models/General';
import Context from '../../../../context/UserContext';
import { sendErrorSubmit } from '../../../../helpers/links';
import useSidebar from '../../../../stores/links/useSidebar';
import { LinkFormDefault, ObjEditFormLink } from '../../../../models/LinkForm';
import { IS_USER_ADMIN } from '../../../../constants/user';
import { GlobalContextType } from '../../../../models/Context';
import GeneralContext from '../../../../context/GeneralActions';
import AddWarningLink from '../../AddWarningLink';

interface ClaimContainerProps {
  owners: Owners[];
  sites: BasicArray[];
  handleFeedbackMessage: (message: string, severity?: SeverityType) => void;
  tabView: number;
}

const ClaimContainer: React.FC<ClaimContainerProps> = ({
  owners,
  sites,
  handleFeedbackMessage,
  tabView,
}) => {
  const {
    claimId,
    loadingForm,
    updateLoadingForm,
    linkForm,
    replaceObjEditForm,
    updateClaimId,
  } = useLinkForm();
  const [type, setType] = useState('read');
  const [claimLinkEdit, setClaimLinkEdit] = useState<ClaimsInt>(claimsObjDef);
  const [disabledClaimSave, setDisabledClaimSave] = useState(false);
  const [claimObj, setClaimObj] =
    useState<ClaimPostPutInt>(claimPostObjDefault);
  const { UserContext } = useContext(Context) as UserContextType;
  const [loadingSubmitClaimLink, setLoadingSubmitClaimLink] = useState(false);
  const { updateSidebar } = useSidebar();
  const [readMode, setReadMode] = useState(true);
  const { GlobalContext, setGlobalContext } = useContext(
    GeneralContext,
  ) as GlobalContextType;
  const [openWarning, setOpenWarning] = useState(false);
  const closeWarning = (value: boolean) => setOpenWarning(value);

  const [openModalReject, setOpenModalReject] = useState(false);
  const modalRejectOpen = () => setOpenModalReject(true);
  const closeModalReject = () => setOpenModalReject(false);

  const [openModalApprove, setOpenModalApprove] = useState(false);
  const modalApproveOpen = () => setOpenModalApprove(true);
  const closeModalApprove = () => setOpenModalApprove(false);

  const saveNewClaimLink = async (obj?: ClaimPostPutInt) => {
    setLoadingSubmitClaimLink(true);
    const copyObject = { ...claimObj };
    if (obj) {
      if (obj.audience === 0 || obj.audience === undefined) {
        delete obj.audience;
      }
    } else if (claimObj.audience === 0 || claimObj.audience === undefined) {
      delete copyObject.audience;
    }
    try {
      await createClaimLink(obj ?? copyObject);
      handleFeedbackMessage(`Claim created successfully`, 'success');
      updateSidebar({ type: 'read' });
      setClaimObj(claimPostObjDefault);
      replaceObjEditForm(
        ObjEditFormLink,
        LinkFormDefault,
        IS_USER_ADMIN.includes(UserContext.profile.role),
      );
      setTimeout(() => {
        setType('read');
      }, 2000);
      updateClaimId(0);
      closeModalApprove();
      closeModalReject();
      if (tabView === 1)
        setGlobalContext({
          ...GlobalContext,
          unclaimed: {
            ...GlobalContext.unclaimed,
            page: GlobalContext.unclaimed.page,
          },
        });
    } catch (err) {
      closeModalApprove();
      closeModalReject();
      const error = err as any;

      const url = `POST ${process.env.REACT_APP_API_BASE_URL}/api/v2/link-claims/`;
      await sendErrorSubmit({
        request_url: url,
        request_payload: JSON.stringify(obj ?? copyObject),
        response: JSON.stringify(error.data),
        token: UserContext.token,
      });
      if (
        error.data.targets &&
        error.data.targets.find(
          (item: { destination_url?: any }) => item.destination_url,
        )
      ) {
        handleFeedbackMessage('Malformed link for Destination Url', 'error');
      } else {
        handleFeedbackMessage(`Error creating claim`, 'error');
      }
    } finally {
      setLoadingSubmitClaimLink(false);
    }
  };

  const saveClaimLink = async (obj?: ClaimPostPutInt, id?: number) => {
    setLoadingSubmitClaimLink(true);
    const copyObject = { ...claimObj };
    if (obj) {
      if (obj.audience === 0 || obj.audience === undefined) {
        delete obj.audience;
      }
    } else if (claimObj.audience === 0 || claimObj.audience === undefined) {
      delete copyObject.audience;
    }
    try {
      await editClaimLink(obj ?? copyObject, claimLinkEdit.id);
      handleFeedbackMessage(`Claim updated successfully`, 'success');
      setReadMode(true);
      closeModalApprove();
      closeModalReject();
      if (tabView === 2)
        setGlobalContext({
          ...GlobalContext,
          claims: {
            ...GlobalContext.claims,
            page: GlobalContext.claims.page,
          },
        });
    } catch (err) {
      closeModalApprove();
      closeModalReject();
      const error = err as any;
      const url = `PUT ${process.env.REACT_APP_API_BASE_URL}/api/v2/link-claims/${claimLinkEdit.id}/`;
      await sendErrorSubmit({
        request_url: url,
        request_payload: JSON.stringify(obj ?? copyObject),
        response: JSON.stringify(error.data),
        token: UserContext.token,
      });
      if (
        error.data.targets &&
        error.data.targets.find(
          (item: { destination_url?: any }) => item.destination_url,
        )
      ) {
        handleFeedbackMessage('Malformed link for Destination Url', 'error');
      } else {
        handleFeedbackMessage(`Error updating claim`, 'error');
      }
    } finally {
      setLoadingSubmitClaimLink(false);
    }
  };

  const fetchClaimInfo = async () => {
    if (claimId === 0) {
      setClaimLinkEdit(claimsObjDef);
      setDisabledClaimSave(true);
      setClaimObj(claimPostObjDefault);
      setReadMode(true);
      setType('read');
      return;
    }
    updateLoadingForm(true);
    try {
      if (tabView >= 2) {
        const resp = await getClaimInfo(claimId);
        setClaimLinkEdit(resp);
        if (type !== 'edit') setType('edit');
        return;
      }
      const resp = await getLinksClaims(claimId);
      if (resp.length > 0) {
        setClaimLinkEdit(resp[0]);
        setType('edit');
        return;
      }

      setType('create');
    } catch (err) {
      console.log(err);
    } finally {
      setTimeout(() => {
        updateLoadingForm(false);
      }, 2000);
    }
  };

  useEffect(() => {
    setType('read');
    setReadMode(true);
    fetchClaimInfo();
  }, [claimId]);

  const handleClaimDisabled = (value: boolean) => setDisabledClaimSave(value);
  const handleUpdateClaimObj = (obj: ClaimPostPutInt) => setClaimObj(obj);

  return (
    <>
      <Typography
        fontWeight={600}
        fontSize={15}
        textTransform="capitalize"
        textAlign="center"
      >
        {type === 'create'
          ? 'Create Claim'
          : tabView === 2
          ? 'Update Claim'
          : 'Link Details'}
      </Typography>
      <FlexBoxBetween mb={1.5} mt={2}>
        {type !== 'create' && !readMode && claimId !== 0 ? (
          <MainButton
            onClick={() => closeWarning(true)}
            disabled={false}
            variant="contained"
            sx={{
              fontSize: 12,
              backgroundColor: '#FFFFFF',
              color: 'black',
              border: '1px solid grey',
              '&:hover': {
                backgroundColor: '#FFFFFF',
              },
            }}
          >
            Cancel
          </MainButton>
        ) : (
          <div />
        )}

        {type !== 'create' && readMode && tabView === 2 && (
          <MainButton
            loading={loadingSubmitClaimLink}
            variant="contained"
            sx={{
              background: '#84D0F0',
              color: 'black',
              '&:hover': {
                backgroundColor: '#84D0F0',
              },
            }}
            disabled={claimId === 0}
            onClick={() => setReadMode(false)}
          >
            Edit Claim
          </MainButton>
        )}
        {type !== 'create' && !readMode && (
          <MainButton
            loading={loadingSubmitClaimLink}
            variant="contained"
            sx={{
              marginX: 1,
              background: '#BAF372',
              fontSize: 12,
              color: 'black',
              '&:hover': {
                backgroundColor: '#BAF372',
              },
            }}
            disabled={disabledClaimSave}
            onClick={() => saveClaimLink()}
          >
            Save
          </MainButton>
        )}
        {/* {type === 'create' && (
          <MainButton
            loading={loadingSubmitClaimLink}
            variant="contained"
            sx={{
              marginX: 1,
              background: '#BAF372',
              fontSize: 12,
              color: 'black',
              '&:hover': {
                backgroundColor: '#BAF372',
              },
            }}
            disabled={disabledClaimSave}
            onClick={() => saveNewClaimLink()}
          >
            Save
          </MainButton>
        )} */}
      </FlexBoxBetween>

      {!loadingForm && type === 'read' && (
        <ClaimForm
          readMode={UserContext.profile.role === 'trainer' || readMode}
          loading={loadingSubmitClaimLink}
          owners={owners}
          handleClaimDisabled={handleClaimDisabled}
          claimLinks={claimLinkEdit}
          handleSaveForm={saveClaimLink}
          sites={[{ id: claimLinkEdit.site.id, name: claimLinkEdit.site.name }]}
          handleUpdateClaimObj={handleUpdateClaimObj}
          readModeOn={readMode}
          openModalApprove={openModalApprove}
          openModalReject={openModalReject}
          closeModalApprove={closeModalApprove}
          closeModalReject={closeModalReject}
          modalApproveOpen={modalApproveOpen}
          type={type}
          modalRejectOpen={modalRejectOpen}
        />
      )}

      {!loadingForm && type === 'edit' && (
        <ClaimForm
          readMode={
            UserContext.profile.role === 'trainer' || readMode || tabView === 3
          }
          loading={loadingSubmitClaimLink}
          owners={owners}
          handleClaimDisabled={handleClaimDisabled}
          claimLinks={claimLinkEdit}
          handleSaveForm={saveClaimLink}
          sites={[{ id: claimLinkEdit.site.id, name: claimLinkEdit.site.name }]}
          handleUpdateClaimObj={handleUpdateClaimObj}
          readModeOn={readMode}
          openModalApprove={openModalApprove}
          openModalReject={openModalReject}
          closeModalApprove={closeModalApprove}
          closeModalReject={closeModalReject}
          modalApproveOpen={modalApproveOpen}
          modalRejectOpen={modalRejectOpen}
          type={type}
        />
      )}

      {!loadingForm && type === 'create' && (
        <ClaimForm
          owners={owners}
          handleSaveForm={saveNewClaimLink}
          objEdit={linkForm}
          handleClaimDisabled={handleClaimDisabled}
          sites={sites}
          handleUpdateClaimObj={handleUpdateClaimObj}
          readModeOn={false}
          readMode={UserContext.profile.role === 'trainer'}
          openModalApprove={openModalApprove}
          openModalReject={openModalReject}
          closeModalApprove={closeModalApprove}
          closeModalReject={closeModalReject}
          modalApproveOpen={modalApproveOpen}
          type={type}
          modalRejectOpen={modalRejectOpen}
          loading={loadingSubmitClaimLink}
        />
      )}

      <AddWarningLink
        open={openWarning}
        closeModal={closeWarning}
        body="If you decide to continue, your updates will not be saved"
        continueModal={() => {
          setClaimObj(claimPostObjDefault);
          replaceObjEditForm(
            ObjEditFormLink,
            LinkFormDefault,
            IS_USER_ADMIN.includes(UserContext.profile.role),
          );

          updateClaimId(0);
          updateSidebar({ type: 'read' });
          closeWarning(false);
          setTimeout(() => {
            setType('read');
          }, 2000);
        }}
      />
    </>
  );
};

export default ClaimContainer;
