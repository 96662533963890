/* eslint-disable no-nested-ternary */
import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  IconButton,
  ListItemIcon,
  Tooltip,
  ListItemText,
} from '@mui/material';
import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import drawerClosed from '../../../assets/icons/Drawer/drawer.png';
import drawerOpen from '../../../assets/icons/Drawer/drawerDark.png';
import Context from '../../../context/UserContext';
import { UserContextType } from '../../../models/User';
import routesNames from '../../../routes/customRoutes';
import { Drawer, DrawerHeader } from './DrawerComponents/DrawerComponents';
import {
  affinitiesIcon,
  homeIcon,
  templatesIcon,
  configurationIcon,
  historyIcon,
} from './DrawerComponents/Icons';
import { isUserRouteValid } from '../../../helpers/helpersfunctions';
import { USERS_WITH_SETTINGS_ACCESS } from '../../../constants/user';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ADMINS_ROLES = ['dev admin', 'administrator'];

const Sidebar = ({ open, setOpen }: Props) => {
  const { UserContext } = useContext(Context) as UserContextType;
  const location = useLocation();
  const navigate = useNavigate();

  const items = [
    {
      id: 1,
      text: 'Home',
      icon: homeIcon,
      path: routesNames.home,
      admin: false,
    },
    {
      id: 2,
      text: 'History',
      icon: historyIcon,
      path: routesNames.history,
      admin: false,
    },
    {
      id: 3,
      text: 'Templates',
      icon: templatesIcon,
      path: routesNames.templates,
      admin: false,
    },
    {
      id: 4,
      text: 'Prospecting',
      icon: affinitiesIcon,
      path: routesNames.prospecting,
      admin: true,
    },
  ];

  const settings = [
    {
      id: 5,
      text: 'Settings',
      icon: configurationIcon,
      path: routesNames.users,
    },
  ];
  return (
    <Drawer
      open={open}
      variant="permanent"
      anchor="left"
      sx={{ backgroundColor: '#D9D9D9' }}
    >
      <div className="drawer-list_container">
        <div>
          <DrawerHeader
            sx={
              open
                ? { alignItems: 'center', justifyContent: 'start' }
                : { alignItems: 'center', justifyContent: 'center' }
            }
          >
            <IconButton onClick={() => setOpen(!open)} sx={{ borderRadius: 0 }}>
              {open && (
                <div className="drawer-container_open">
                  <img src={drawerOpen} alt="Drawer Icon" />
                </div>
              )}
              {!open && (
                <div className="drawer-container_closed">
                  <img src={drawerClosed} alt="Drawer Icon" />
                </div>
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List className="drawer-list">
            {items.map(item => (
              <>
                {ADMINS_ROLES.includes(UserContext.profile.role) && (
                  <ListItem
                    key={item.id}
                    disablePadding
                    sx={{
                      marginBottom: 1.2,
                    }}
                  >
                    <Tooltip title={item.text} placement="right">
                      <ListItemButton
                        onClick={() => navigate(item.path)}
                        className={
                          location.pathname === item.path
                            ? 'drawer-selected'
                            : open
                            ? 'drawer-item_open'
                            : 'drawer-items'
                        }
                        sx={{
                          minHeight: 48,
                          justifyContent: 'initial',
                          px: item.id === 1 ? 2.3 : '',
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                          }}
                        >
                          <item.icon open={open} />
                        </ListItemIcon>
                        <ListItemText
                          primary={item.text}
                          sx={{ opacity: open ? 1 : 0, color: '#FFFF' }}
                        />
                      </ListItemButton>
                    </Tooltip>
                  </ListItem>
                )}
                {!ADMINS_ROLES.includes(UserContext.profile.role) &&
                  isUserRouteValid(UserContext.profile.role, item.path) && (
                    <ListItem
                      key={item.id}
                      disablePadding
                      sx={{ display: 'block', marginBottom: 1.2 }}
                    >
                      <Tooltip title={item.text} placement="right">
                        <ListItemButton
                          onClick={() => navigate(item.path)}
                          className={
                            location.pathname === item.path
                              ? 'drawer-selected'
                              : open
                              ? 'drawer-item_open'
                              : 'drawer-items'
                          }
                          sx={{
                            minHeight: 48,
                            justifyContent: 'initial',
                            px: item.id === 1 ? 2.3 : '',
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : 'auto',
                              justifyContent: 'center',
                            }}
                          >
                            <item.icon open={open} />
                          </ListItemIcon>
                          <ListItemText
                            primary={item.text}
                            sx={{ opacity: open ? 1 : 0, color: '#FFFF' }}
                          />
                        </ListItemButton>
                      </Tooltip>
                    </ListItem>
                  )}
              </>
            ))}
          </List>
        </div>
        {USERS_WITH_SETTINGS_ACCESS.includes(UserContext.profile.role) && (
          <div className="bottom">
            <Divider />
            <List className="drawer-list">
              {settings.map(item => (
                <ListItem
                  key={item.id}
                  disablePadding
                  sx={{ display: 'block', marginBottom: 1.2 }}
                >
                  <Tooltip title={item.text} placement="right">
                    <ListItemButton
                      onClick={() => navigate(item.path)}
                      className={
                        location.pathname.includes(item.path)
                          ? 'drawer-selected'
                          : open
                          ? 'drawer-item_open'
                          : 'drawer-items'
                      }
                      sx={{
                        minHeight: 48,
                        justifyContent: 'initial',
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : 'auto',
                          justifyContent: 'center',
                        }}
                      >
                        <item.icon open={open} />
                      </ListItemIcon>
                      <ListItemText
                        primary={item.text}
                        sx={{ opacity: open ? 1 : 0, color: '#FFFF' }}
                      />
                    </ListItemButton>
                  </Tooltip>
                </ListItem>
              ))}
            </List>
          </div>
        )}
      </div>
    </Drawer>
  );
};

export default Sidebar;
