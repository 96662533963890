/* eslint-disable import/prefer-default-export */
import { LogsRequestI, LogsResponseI } from '../models/Logs';
import APIClient from '../services/APIClient';

export const fetchLogs = async (request: LogsRequestI) => {
  const clientAPI = new APIClient();
  const logsResponse = await clientAPI.client.get<LogsResponseI>(
    `/v2/activity-logs/?page=${request.page}&page_size=${request.page_size}&ordering=${request.ordering}${request.filters}`,
  );
  return logsResponse.data;
};

export const rescheduleLog = async (obj: {
  message_id: number;
  new_scheduled_date: string;
}) => {
  const clientAPI = new APIClient();
  const logsResponse = await clientAPI.client.post<LogsResponseI>(
    `/v2/outreach-queue/reschedule-message/`,
    obj,
  );
  return logsResponse.data;
};
