/* eslint-disable no-param-reassign */
import React, { useContext, useEffect, useState } from 'react';
import { UserContextType } from '../../../../models/User';
import Context from '../../../../context/UserContext';
import {
  claimPostObjDefault,
  ClaimPostPutInt,
  ClaimsInt,
  claimsObjDef,
} from '../../../../models/Links';
import useOwners from '../../../../customHooks/useOwners';
import {
  createClaimLink,
  editClaimLink,
  getLinksClaims,
} from '../../../../helpers/claims';
import { sendErrorSubmit } from '../../../../helpers/links';
import HeaderDrawer from '../../../../components/Drawers/HeaderDrawer';
import Spinner from '../../../../components/Loading/Spinner';
import ClaimTab from '../../../../components/Forms/Links/ClaimTab';
import useLinkForm from '../../../../stores/links/useLinkForm';
import { ObjectLinks } from '../../../../models/Forms';

interface LinkFormClaimProps {
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  fetchInfo?: () => Promise<void>;
  handleMessage: (message: string, severity: 'success' | 'error') => void;
  claimLink: number;
  disabledAll?: boolean;
  sites: { id: number; name: string; status: string }[];
  saveLink: (email?: boolean, copyLinkForm?: ObjectLinks) => Promise<void>;
  warningModal: (value: boolean) => void;
}

const LinkFormClaim = ({
  setOpenDrawer,
  handleMessage,
  fetchInfo,
  claimLink,
  sites,
  disabledAll,
  saveLink,
  warningModal,
}: LinkFormClaimProps) => {
  const { UserContext } = useContext(Context) as UserContextType;
  const [claimObj, setClaimObj] =
    useState<ClaimPostPutInt>(claimPostObjDefault);
  const [disabledClaimSave, setDisabledClaimSave] = useState(false);
  const [loadingSubmitClaimLink, setLoadingSubmitClaimLink] = useState(false);
  const { owners } = useOwners({ withFields: true });
  const [claimLinkEdit, setClaimLinkEdit] = useState<ClaimsInt>(claimsObjDef);
  const [loading, setLoading] = useState(false);
  const { linkForm, updateLinkForm } = useLinkForm();

  const [type, setType] = useState('');

  const saveNewClaimLink = async (obj?: ClaimPostPutInt) => {
    setLoadingSubmitClaimLink(true);
    const copyObject = { ...claimObj };
    if (obj) {
      if (obj.audience === 0 || obj.audience === undefined) {
        delete obj.audience;
      }
    } else if (claimObj.audience === 0 || claimObj.audience === undefined) {
      delete copyObject.audience;
    }
    try {
      const resp = await createClaimLink(obj ?? copyObject);
      handleMessage(`Claim created successfully`, 'success');
      updateLinkForm({
        audience: obj ? obj.audience ?? 0 : copyObject.audience ?? 0,
        affinity: obj ? obj.affinity ?? 0 : copyObject.affinity ?? 0,
        template: obj ? obj.template ?? 0 : copyObject.template ?? 0,
      });
      setOpenDrawer(false);
      saveLink();
    } catch (err) {
      const error = err as any;

      const url = `POST ${process.env.REACT_APP_API_BASE_URL}/api/v2/link-claims/`;
      await sendErrorSubmit({
        request_url: url,
        request_payload: JSON.stringify(obj ?? copyObject),
        response: JSON.stringify(error.data),
        token: UserContext.token,
      });
      if (
        error.data.targets &&
        error.data.targets.find(
          (item: { destination_url?: any }) => item.destination_url,
        )
      ) {
        handleMessage('Malformed link for Destination Url', 'error');
      } else {
        handleMessage(`Error creating claim`, 'error');
      }
    } finally {
      setLoadingSubmitClaimLink(false);
    }
  };

  const saveClaimLink = async (obj?: ClaimPostPutInt, id?: number) => {
    setLoadingSubmitClaimLink(true);
    const copyObject = { ...claimObj };
    if (obj) {
      if (obj.audience === 0 || obj.audience === undefined) {
        delete obj.audience;
      }
    } else if (claimObj.audience === 0 || claimObj.audience === undefined) {
      delete copyObject.audience;
    }
    try {
      const resp = await editClaimLink(obj ?? copyObject, claimLinkEdit.id);
      handleMessage(`Claim updated successfully`, 'success');
      updateLinkForm({
        audience: obj ? obj.audience ?? 0 : copyObject.audience ?? 0,
        affinity: obj ? obj.affinity ?? 0 : copyObject.affinity ?? 0,
        template: obj ? obj.template ?? 0 : copyObject.template ?? 0,
      });
      setOpenDrawer(false);
      saveLink();
    } catch (err) {
      const error = err as any;

      const url = `PUT ${process.env.REACT_APP_API_BASE_URL}/api/v2/link-claims/${claimLinkEdit.id}/`;
      await sendErrorSubmit({
        request_url: url,
        request_payload: JSON.stringify(obj ?? copyObject),
        response: JSON.stringify(error.data),
        token: UserContext.token,
      });
      if (
        error.data.targets &&
        error.data.targets.find(
          (item: { destination_url?: any }) => item.destination_url,
        )
      ) {
        handleMessage('Malformed link for Destination Url', 'error');
      } else {
        handleMessage(`Error updating claim`, 'error');
      }
    } finally {
      setLoadingSubmitClaimLink(false);
    }
  };

  const handleClaimDisabled = (value: boolean) => setDisabledClaimSave(value);
  const handleUpdateClaimObj = (obj: ClaimPostPutInt) => setClaimObj(obj);

  const fetchClaimInfo = async () => {
    try {
      const resp = await getLinksClaims(claimLink);
      if (resp.length > 0) {
        setClaimLinkEdit(resp[0]);
        setType('edit');
        return;
      }

      setType('create');
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchClaimInfo();
  }, [claimLink]);

  const disabledEdit = () => {
    const firstResp = [
      'manager',
      'account manager',
      'administrator',
      'dev admin',
      'strategist',
    ].includes(UserContext.profile.role);
    if (firstResp) return false;
    if (claimLinkEdit.owner.id === UserContext.id) return false;
    return true;
  };

  return (
    <>
      <HeaderDrawer
        saveFunction={type === 'create' ? saveNewClaimLink : saveClaimLink}
        title={type === 'create' ? 'Create Claim' : 'Edit Claim'}
        threeButtons={false}
        loading={loadingSubmitClaimLink}
        disabled={disabledClaimSave}
        closeDrawer={setOpenDrawer}
        hideButtons={type === 'create'}
      />
      {loading && <Spinner />}
      {!loading && type === 'edit' && (
        <ClaimTab
          readMode={disabledAll}
          loading={loadingSubmitClaimLink}
          owners={owners}
          handleClaimDisabled={handleClaimDisabled}
          claimLinks={claimLinkEdit}
          handleSaveForm={saveClaimLink}
          sites={[{ id: claimLinkEdit.site.id, name: claimLinkEdit.site.name }]}
          handleUpdateClaimObj={handleUpdateClaimObj}
          type={type}
          warningModal={warningModal}
        />
      )}
      {!loading && type === 'create' && (
        <ClaimTab
          owners={owners}
          handleSaveForm={saveNewClaimLink}
          objEdit={linkForm}
          handleClaimDisabled={handleClaimDisabled}
          sites={sites}
          handleUpdateClaimObj={handleUpdateClaimObj}
          loading={loadingSubmitClaimLink}
          type={type}
          readMode={disabledAll}
          setOpenDrawer={setOpenDrawer}
          warningModal={warningModal}
        />
      )}
    </>
  );
};

export default LinkFormClaim;
