import { Box, TableCell, TableRow, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { useState, useEffect, useContext } from 'react';
import {
  addProject,
  fetchSingleProject,
  updateProject,
} from '../../../helpers/projects';
import {
  ProjectFormInt,
  PropsProjectForm,
  UsersAssignedSelected,
} from '../../../models/Forms';
import {
  BasicArray,
  SeverityType,
  statusOptions,
  tabs,
} from '../../../models/General';
import HeaderDrawer from '../../Drawers/HeaderDrawer';
import FeedbackMessage from '../../FeedbackMessage';
import Spinner from '../../Loading/Spinner';
import TabPanel from '../../Tab/TabPanel';
import TabParent from '../../Tab/TabParent';
import BoxAddSettings from '../BoxAddSettings';
import { BasicSelect, SelectFormInput, TextFormField } from '../FormInputs';
import FormNotes from '../FormNotes';
import FormStatus from '../FormStatus';
import StatusModal from '../StatusModal';
import useNotes from '../../../customHooks/useNotes';
import useHistoryTab from '../../../customHooks/useHistoryTab';
import Context from '../../../context/UserContext';
import { UserContextType, UserResponse } from '../../../models/User';
import { sendBackObject } from '../../../helpers/helpersfunctions';
import { ROLES_PROJECTS } from '../../../constants/user';
import UsersAssignedSelection from '../Site/UsersAssignedSelection';
import { MainButton } from '../../buttons';
import UsersSiteTable from '../Site/UsersSiteTable';
import { getAllUsers } from '../../../helpers/user';

const AddProject = ({
  setOpenDrawer,
  clientsOptions,
  categoryOptions,
  clientValue,
  categoryValue,
  type,
  createSuccess,
  clientsCategories,
  projectEdit,
}: PropsProjectForm) => {
  const [openModalUsersAssigned, setOpenModalUsersAssigned] = useState(false);
  const { UserContext } = useContext(Context) as UserContextType;
  const { notes, loadingNotes, refreshNotes } = useNotes({
    id: projectEdit ?? 0,
    type: 'project',
  });
  const { history } = useHistoryTab({ id: projectEdit ?? 0, type: 'project' });
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [usersArr, setUsersArr] = useState<UserResponse[]>([]);
  const [usersCount, setUsersCount] = useState(0);

  const [originalState, setOriginalState] = useState('');
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [tabDrawer, setTabDrawer] = useState(0);
  const [messagesProps, setMessagesProps] = useState<{
    message: string;
    severity: SeverityType;
  }>({
    message: '',
    severity: 'error',
  });
  const [openMessage, setOpenMessage] = useState(false);
  const [render, setRender] = useState(0);
  const [loadingSave, setloadingSave] = useState(false);
  const [categoriesArr, setCategoriesArr] = useState(categoryOptions);
  const [projectForm, setProjectForm] = useState<ProjectFormInt>({
    name: '',
    status: '',
    client: clientValue,
    category: categoryValue,
    text_note: '',
    users_assigned: [],
  });

  const canEdit =
    type === 'edit'
      ? !sendBackObject(UserContext.profile.role, ROLES_PROJECTS).edit
      : !sendBackObject(UserContext.profile.role, ROLES_PROJECTS).add;

  const inputsArr = [
    {
      id: 0,
      value: projectForm.name,
      fullSpace: true,
      required: true,
      validate: false,
      validation: [],
      body: (
        <TextFormField
          required
          disabled={canEdit}
          nameInput="name"
          label="Name"
          error={false}
          value={projectForm.name}
          errorText=""
          placeholder="Name"
          onChange={(value: string) =>
            setProjectForm({ ...projectForm, name: value })
          }
        />
      ),
    },
    {
      id: 1,
      value: projectForm.client,
      fullSpace: true,
      required: true,
      validate: false,
      validation: [],
      body: (
        <SelectFormInput
          clearIcon
          required
          options={clientsOptions}
          disabled={canEdit}
          placeholder="Client"
          value={
            clientsOptions.find(item => item.id === projectForm.client) ?? {
              id: 0,
              name: '',
            }
          }
          onChange={(value: BasicArray | null) => {
            setProjectForm({
              ...projectForm,
              client: value ? (value.id as number) : 0,
            });
          }}
        />
      ),
    },
    {
      id: 2,
      value: projectForm.category,
      fullSpace: true,
      required: true,
      validate: false,
      validation: [],
      body: (
        <SelectFormInput
          clearIcon
          required
          options={categoriesArr}
          disabled={canEdit}
          placeholder="Category"
          value={
            categoriesArr.find(item => item.id === projectForm.category) ?? {
              id: 0,
              name: '',
            }
          }
          onChange={(value: BasicArray | null) => {
            setProjectForm({
              ...projectForm,
              category: value ? (value.id as number) : 0,
            });
          }}
        />
      ),
    },
  ];

  const updateStatus = (note: string) => {
    const newStatus = projectForm.status === 'active' ? 'inactive' : 'active';
    setProjectForm({ ...projectForm, text_note: note, status: newStatus });
    setOpenModal(false);
  };

  if (type === 'edit') {
    inputsArr.splice(1, 0, {
      id: 3,
      value: projectForm.status,
      required: true,
      validate: false,
      validation: [],
      fullSpace: true,
      body: (
        <BasicSelect
          label="Status"
          required
          nameInput="status"
          disabled={canEdit}
          value={projectForm.status}
          options={statusOptions}
          onChange={(value: string) => {
            if (value !== originalState) setOpenModal(true);
            else {
              setProjectForm({
                ...projectForm,
                status: value,
                text_note: '',
              });
            }
          }}
        />
      ),
    });
  }

  const saveProject = async () => {
    setloadingSave(true);
    const obj = {
      name: projectForm.name,
      text_note: type === 'add' ? 'New Project' : projectForm.text_note,
      status: type === 'add' ? 'active' : projectForm.status,
      category: projectForm.category,
      users_assigned:
        projectForm.users_assigned.length === 0
          ? []
          : projectForm.users_assigned.map(item => {
              return {
                id: item.id,
                date_effective: dayjs(item.date_effective).format(),
              };
            }),
    };
    try {
      if (type === 'add') await addProject(obj);
      if (type === 'edit') await updateProject(obj, projectEdit ?? 0);
      setOpenDrawer(false);
      createSuccess(
        type === 'add'
          ? 'Project created successfully'
          : 'Project updated successfully',
        'project',
      );
    } catch (err) {
      const error = err as any;
      if (error.data.name) {
        setMessagesProps({
          severity: 'error',
          message: error.data.name[0],
        });
      } else {
        setMessagesProps({
          severity: 'error',
          message: 'There was an error, please try again',
        });
      }
      setOpenMessage(true);
    } finally {
      setloadingSave(false);
    }
  };

  useEffect(() => {
    if (render !== 0) {
      setProjectForm({ ...projectForm, category: 0 });
      const cats = clientsCategories.find(
        item => item.id === projectForm.client,
      );
      setCategoriesArr(cats ? cats.categories : []);
    }
  }, [projectForm.client]);

  const getInfoProject = async () => {
    try {
      const resp = await fetchSingleProject(projectEdit ?? 0);
      setProjectForm({
        ...projectForm,
        name: resp.name,
        status: resp.status,
        category: resp.categories[0].id,
        users_assigned: resp.users_assigned.map(item => {
          return {
            ...item,
            date_effective: item.date_effective
              ? dayjs(item.date_effective).format('MM/DD/YYYY')
              : dayjs().format('MM/DD/YYYY'),
          };
        }),
      });
      setOriginalState(resp.status);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchUsers = async () => {
    setIsLoadingUsers(true);
    try {
      const users = await getAllUsers(1, 10, '', 'username');
      setUsersArr(users.results);
      setUsersCount(users.count);
    } catch (err) {
      setUsersArr([]);
      setUsersCount(0);
    } finally {
      setIsLoadingUsers(false);
    }
  };

  const updateUsersAssigned = (usersArray: UsersAssignedSelected[]) =>
    setProjectForm({ ...projectForm, users_assigned: usersArray });

  const getPromises = async () => Promise.all([getInfoProject(), fetchUsers()]);

  useEffect(() => {
    setRender(1);

    if (type === 'edit') {
      setLoading(true);
      getPromises();
    } else {
      fetchUsers();
    }
  }, []);

  return (
    <>
      <HeaderDrawer
        title={type === 'add' ? 'Add Project' : 'Edit Project'}
        threeButtons={false}
        loading={loadingSave}
        closeDrawer={setOpenDrawer}
        disabled={
          !(projectForm.name !== '' && projectForm.category !== 0) || canEdit
        }
        saveFunction={saveProject}
      />
      {type === 'edit' && (
        <>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              marginTop: 1.3,
              marginLeft: 3,
              marginRight: 4,
            }}
          >
            <TabParent
              valueTab={tabDrawer}
              setTab={setTabDrawer}
              tabs={tabs}
              center
            />
          </Box>
          <TabPanel value={tabDrawer} index={0}>
            {loading && <Spinner />}
            {!loading && (
              <>
                <BoxAddSettings title="Project Info">
                  <div className="form-grids">
                    {inputsArr.map(input => (
                      <div
                        key={input.id}
                        className={`mb-2 ${
                          input.fullSpace ? 'whole-grid_three' : ''
                        }`}
                      >
                        {input.body}
                      </div>
                    ))}
                  </div>
                </BoxAddSettings>
                <BoxAddSettings
                  pt={0}
                  mb={6}
                  title="Users Assigned"
                  rightTitle={
                    projectForm.users_assigned.length > 0
                      ? {
                          title: 'Manage',
                          onClick: () => setOpenModalUsersAssigned(true),
                        }
                      : undefined
                  }
                >
                  {projectForm.users_assigned.length === 0 && (
                    <Box
                      display="flex"
                      justifyContent="center"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <Typography textAlign="center">
                        There are no users selected yet
                      </Typography>
                      <MainButton
                        variant="contained"
                        sx={{
                          background: 'rgba(0, 0, 0, 0.1)',
                          color: 'black',
                          marginTop: 2,
                          '&:hover': {
                            backgroundColor: '#F2F2F2',
                          },
                        }}
                        onClick={() => setOpenModalUsersAssigned(true)}
                      >
                        Add New
                      </MainButton>
                    </Box>
                  )}
                  {projectForm.users_assigned.length > 0 && (
                    <UsersSiteTable
                      columnsNames={{ first: 'Name', second: 'Date' }}
                    >
                      {projectForm.users_assigned.map(user => (
                        <TableRow key={user.id} hover>
                          <TableCell component="th" scope="row">
                            {user.first_name} {user.last_name}
                          </TableCell>
                          <TableCell align="right">
                            {user.date_effective}
                          </TableCell>
                        </TableRow>
                      ))}
                    </UsersSiteTable>
                  )}
                </BoxAddSettings>
              </>
            )}
          </TabPanel>
          <TabPanel value={tabDrawer} index={1}>
            <BoxAddSettings>
              <FormNotes
                getNotes={refreshNotes}
                loadingNotes={loadingNotes}
                type="project"
                notes={notes}
                id={projectEdit ?? 0}
                setMessage={setMessagesProps}
                openMessage={setOpenMessage}
              />
            </BoxAddSettings>
          </TabPanel>
          <TabPanel value={tabDrawer} index={2}>
            <BoxAddSettings>
              <FormStatus history={history} />
            </BoxAddSettings>
          </TabPanel>
        </>
      )}
      {type === 'add' && (
        <>
          <BoxAddSettings title="Project Info">
            <div className="form-grids">
              {inputsArr.map(input => (
                <div
                  key={input.id}
                  className={`mb-2 ${
                    input.fullSpace ? 'whole-grid_three' : ''
                  }`}
                >
                  {input.body}
                </div>
              ))}
            </div>
          </BoxAddSettings>
          <BoxAddSettings
            pt={0}
            mb={6}
            title="Users Assigned"
            rightTitle={
              projectForm.users_assigned.length > 0
                ? {
                    title: 'Manage',
                    onClick: () => setOpenModalUsersAssigned(true),
                  }
                : undefined
            }
          >
            {projectForm.users_assigned.length === 0 && (
              <Box
                display="flex"
                justifyContent="center"
                flexDirection="column"
                alignItems="center"
              >
                <Typography textAlign="center">
                  There are no users selected yet
                </Typography>
                <MainButton
                  variant="contained"
                  sx={{
                    background: 'rgba(0, 0, 0, 0.1)',
                    color: 'black',
                    marginTop: 2,
                    '&:hover': {
                      backgroundColor: '#F2F2F2',
                    },
                  }}
                  onClick={() => setOpenModalUsersAssigned(true)}
                >
                  Add New
                </MainButton>
              </Box>
            )}
            {projectForm.users_assigned.length > 0 && (
              <UsersSiteTable columnsNames={{ first: 'Name', second: 'Date' }}>
                {projectForm.users_assigned.map(user => (
                  <TableRow key={user.id} hover>
                    <TableCell component="th" scope="row">
                      {user.first_name} {user.last_name}
                    </TableCell>
                    <TableCell align="right">{user.date_effective}</TableCell>
                  </TableRow>
                ))}
              </UsersSiteTable>
            )}
          </BoxAddSettings>
        </>
      )}
      <FeedbackMessage
        open={openMessage}
        setOpen={setOpenMessage}
        vertical="top"
        horizontal="right"
        severity={messagesProps.severity}
        message={messagesProps.message}
      />
      <UsersAssignedSelection
        open={openModalUsersAssigned}
        closeModal={setOpenModalUsersAssigned}
        usersDefaultArray={usersArr}
        usersDefaultCount={usersCount}
        usersDefaultLoading={isLoadingUsers}
        updateUsers={updateUsersAssigned}
        selectedUsersForm={projectForm.users_assigned}
      />
      <StatusModal
        open={openModal}
        closeModal={setOpenModal}
        updateStatus={updateStatus}
      />
    </>
  );
};

export default AddProject;
